import { WordData } from "../model/WordData"
import { IconButton, Tooltip } from "@mui/material"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import React from "react"
import { DEFAULT_WORD_DATA } from "../routes/Dashboard"
import { ActionButtonsProps } from "./ActionButtonsProps"

export function ActionButtons(props: ActionButtonsProps) {
  const {
    word,
    setWordToEdit,
    setEditWordDialogIsOpen,
    setTagSelectionForEdit,
    setWordProfileWord,
    setWordProfileDialogIsOpen,
  } = props

  const handleViewButtonClicked = () => {
    setWordProfileWord(word)
    setWordProfileDialogIsOpen(true)
  }

  const handleEditButtonClicked = () => {
    const newWordToEdit: WordData = {
      ...DEFAULT_WORD_DATA,
      ...word, // merges word with DEFAULT_WORD_DATA where word wins
    }
    setWordToEdit(newWordToEdit)
    setEditWordDialogIsOpen(true)
    setTagSelectionForEdit(word.tags.map((t) => t.tag))
  }

  return (
    <>
      <Tooltip title="View" arrow>
        <IconButton color="primary" onClick={handleViewButtonClicked}>
          <VisibilityOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit" arrow>
        <IconButton color="primary" onClick={handleEditButtonClicked}>
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}
