import {
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import { useState } from "react"
import { AxiosResponse } from "axios"
import { CollectionsDialogProps } from "./CollectionsDialogProps"
import { CollectionData } from "../model/CollectionData"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import AddIcon from "@mui/icons-material/Add"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import CollectionEditDialog from "./CollectionEditDialog"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import {
  deleteCollection,
  getCollections,
  patchDefaultCollection,
  postCollection,
  putCollection,
} from "../requests/Requests"
import DeleteDialog from "./DeleteDialog"
import Userfront from "@userfront/react"

export default function CollectionsDialog(props: CollectionsDialogProps) {
  const {
    open,
    setOpen,
    handleClose,
    setCollections,
    collections,
    activeCollection,
    setActiveCollection,
    defaultCollectionId,
    setDefaultCollectionId,
    buttonsEnabled,
    setButtonsEnabled,
    setTableIsLoading,
  } = props

  const [collectionEditDialogOpenEdit, setCollectionEditDialogOpenEdit] =
    useState<boolean>(false)
  const [collectionEditDialogOpenAdd, setCollectionEditDialogOpenAdd] =
    useState<boolean>(false)
  const [closeButtonEnabled, setCloseButtonEnabled] = useState<boolean>(true)

  // edit fields
  const [collectionToEdit, setCollectionToEdit] = useState<
    CollectionData | undefined
  >()
  const [collectionToEditTitle, setCollectionToEditTitle] = useState<string>("")
  const [collectionToEditLanguage1, setCollectionToEditLanguage1] =
    useState<string>("")
  const [collectionToEditLanguage2, setCollectionToEditLanguage2] =
    useState<string>("")

  // add fields
  const [collectionToAddTitle, setCollectionToAddTitle] = useState<string>("")
  const [collectionToAddLanguage1, setCollectionToAddLanguage1] =
    useState<string>("")
  const [collectionToAddLanguage2, setCollectionToAddLanguage2] =
    useState<string>("")

  // delete fields
  const [collectionToDelete, setCollectionToDelete] = useState<
    CollectionData | undefined
  >()
  const [deleteCollectionDialogOpen, setDeleteCollectionDialogOpen] =
    useState<boolean>(false)

  function handleOpenButtonClicked(collection: CollectionData) {
    setOpen(false)
    setActiveCollection(collection)
    setCloseButtonEnabled(true)
    setTableIsLoading(true)
  }

  async function handleDefaultButtonClicked(collection: CollectionData) {
    try {
      const defaultCollectionResponse: AxiosResponse =
        await patchDefaultCollection(Userfront.user.userUuid, collection.id)
      if (defaultCollectionResponse.status !== 200) {
        throw Error("Bad response on patchDefaultCollection")
      }
      setDefaultCollectionId(collection.id)
    } catch (error) {
      console.log(error)
    }
  }

  function handleEditButtonClicked(collection: CollectionData) {
    setCollectionToEdit(collection)
    setCollectionToEditTitle(collection.title)
    setCollectionToEditLanguage1(collection.language1)
    setCollectionToEditLanguage2(collection.language2)
    setCollectionEditDialogOpenEdit(true)
  }

  function handleDeleteButtonClicked(collection: CollectionData) {
    setCollectionToDelete(collection)
    setDeleteCollectionDialogOpen(true)
  }

  function handleAddButtonClicked() {
    setCollectionToEdit(undefined)
    setCollectionEditDialogOpenAdd(true)
  }

  async function handleSubmitEdit() {
    try {
      setButtonsEnabled(false)
      const collectionResponse: AxiosResponse = await putCollection(
        collectionToEdit,
        collectionToEditTitle,
        collectionToEditLanguage1,
        collectionToEditLanguage2
      )
      if (collectionResponse.status !== 200) {
        throw Error("Bad response on putCollection")
      }
      const collectionsResponse: AxiosResponse = await getCollections(
        Userfront.user.userUuid
      )
      if (collectionsResponse.status !== 200) {
        throw Error("Bad response on getCollections")
      }
      setCollections(collectionsResponse.data)
      setCollectionToEdit(undefined)
      setCollectionToEditTitle("")
      setCollectionToEditLanguage1("")
      setCollectionToEditLanguage2("")
      setCollectionEditDialogOpenEdit(false)
    } catch (error) {
      console.log(error)
    }
    setButtonsEnabled(true)
  }

  function handleCancelEdit() {
    setCollectionToEdit(undefined)
    setCollectionEditDialogOpenEdit(false)
  }

  async function handleSubmitAdd() {
    try {
      setButtonsEnabled(false)
      const collectionResponse: AxiosResponse = await postCollection(
        Userfront.user.userUuid,
        collectionToAddTitle,
        collectionToAddLanguage1,
        collectionToAddLanguage2
      )
      if (collectionResponse.status !== 201) {
        throw Error("Bad response on postCollection")
      }
      const collectionsResponse: AxiosResponse = await getCollections(
        Userfront.user.userUuid
      )
      if (collectionsResponse.status !== 200) {
        throw Error("Bad response on getCollections")
      }
      setCollections(collectionsResponse.data)
      setCollectionToAddTitle("")
      setCollectionToAddLanguage1("")
      setCollectionToAddLanguage2("")
      setCollectionEditDialogOpenAdd(false)
    } catch (error) {
      console.log(error)
    }
    setButtonsEnabled(true)
  }

  function handleCancelAdd() {
    setCollectionToAddTitle("")
    setCollectionToAddLanguage1("")
    setCollectionToAddLanguage2("")
    setCollectionEditDialogOpenAdd(false)
  }

  async function handleSubmitDelete() {
    try {
      setButtonsEnabled(false)
      if (!collectionToDelete) {
        throw Error("Cannot delete undefined collection")
      }
      const collectionResponse: AxiosResponse = await deleteCollection(
        collectionToDelete
      )
      if (collectionResponse.status !== 204) {
        throw Error("Bad response on deleteCollection")
      }
      if (collectionToDelete?.id === activeCollection?.id) {
        setActiveCollection(undefined)
        setCloseButtonEnabled(false)
      }
      if (collectionToDelete?.id === defaultCollectionId) {
        setDefaultCollectionId(undefined)
      }
      const collectionsResponse: AxiosResponse = await getCollections(
        Userfront.user.userUuid
      )
      if (collectionsResponse.status !== 200) {
        throw Error("Bad response on getCollections")
      }
      setCollections(collectionsResponse.data)
      setCollectionToDelete(undefined)
      setDeleteCollectionDialogOpen(false)
    } catch (error) {
      console.log(error)
    }
    setButtonsEnabled(true)
  }

  function handleCancelDelete() {
    setDeleteCollectionDialogOpen(false)
    setCollectionToDelete(undefined)
  }

  const DefaultLabel = styled("div")(({ theme }) => ({
    ...theme.typography.button,
    backgroundColor: "#9fd0ff",
    color: "#0f487e",
    height: "16px",
    width: "50px",
    fontSize: "10px",
    textAlign: "center",
    borderRadius: "3px",
  }))

  const OpenedLabel = styled("div")(({ theme }) => ({
    ...theme.typography.button,
    backgroundColor: "#a8d2aa",
    color: "#1c420d",
    height: "16px",
    width: "50px",
    fontSize: "10px",
    textAlign: "center",
    borderRadius: "3px",
  }))

  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Collections
          <Button
            color="primary"
            startIcon={<AddIcon style={{ fontSize: 25 }} />}
            onClick={() => handleAddButtonClicked()}
          >
            Add
          </Button>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell align="left">Language 1</TableCell>
                  <TableCell align="left">Language 2</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collections.map((row: CollectionData) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Box
                        display="flex"
                        gap="5px"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        {row.title}
                        {row.id === defaultCollectionId && (
                          <DefaultLabel>Default</DefaultLabel>
                        )}
                        {row.id === activeCollection?.id && (
                          <OpenedLabel>Opened</OpenedLabel>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="left">{row.language1}</TableCell>
                    <TableCell align="left">{row.language2}</TableCell>
                    <TableCell align="left" sx={{ pl: "5px" }}>
                      <Tooltip title="Open">
                        <IconButton
                          color="primary"
                          aria-label="open"
                          onClick={() => handleOpenButtonClicked(row)}
                        >
                          <LaunchOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          onClick={() => handleEditButtonClicked(row)}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Set as default">
                        <span>
                          {" "}
                          {/* the span is needed here for the tooltip to work b/c IconButton can be disabled */}
                          <IconButton
                            disabled={row.id === defaultCollectionId}
                            color="primary"
                            aria-label="make default"
                            onClick={() => handleDefaultButtonClicked(row)}
                          >
                            <CheckCircleOutlineIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          color="primary"
                          aria-label="delete"
                          onClick={() => handleDeleteButtonClicked(row)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={!closeButtonEnabled}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CollectionEditDialog
        open={collectionEditDialogOpenEdit}
        title={collectionToEditTitle}
        setTitle={setCollectionToEditTitle}
        language1={collectionToEditLanguage1}
        setLanguage1={setCollectionToEditLanguage1}
        language2={collectionToEditLanguage2}
        setLanguage2={setCollectionToEditLanguage2}
        dialogTitle="Edit Collection"
        submitButtonText="Submit"
        handleCancel={handleCancelEdit}
        handleSubmit={handleSubmitEdit}
        buttonsEnabled={buttonsEnabled}
      />
      <CollectionEditDialog
        open={collectionEditDialogOpenAdd}
        title={collectionToAddTitle}
        setTitle={setCollectionToAddTitle}
        language1={collectionToAddLanguage1}
        setLanguage1={setCollectionToAddLanguage1}
        language2={collectionToAddLanguage2}
        setLanguage2={setCollectionToAddLanguage2}
        dialogTitle="Add Collection"
        submitButtonText="Add"
        handleCancel={handleCancelAdd}
        handleSubmit={handleSubmitAdd}
        buttonsEnabled={buttonsEnabled}
      />
      <DeleteDialog
        open={deleteCollectionDialogOpen}
        title="Delete Collection"
        helperText="Are you sure you want to delete this collection? All words and tags in the collection will be deleted as well. WARNING: This action cannot be undone."
        handleSubmit={handleSubmitDelete}
        submitText="Delete"
        handleCancel={handleCancelDelete}
        cancelText="Cancel"
        buttonsEnabled={buttonsEnabled}
      />
    </div>
  )
}
