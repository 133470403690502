import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  styled,
  Typography,
} from "@mui/material"
import { TagsDialogProps } from "./TagsDialogProps"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Box from "@mui/material/Box"
import React, { useState } from "react"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { TagData } from "../model/TagData"
import TagEditDialog from "./TagEditDialog"
import { AxiosResponse } from "axios"
import DeleteDialog from "./DeleteDialog"
import {
  deleteTag,
  getTagOptions,
  getWords,
  postTag,
  putTag,
} from "../requests/Requests"
import AddIcon from "@mui/icons-material/Add"

const TAGS_HELPER =
  "Create a new tag from the 'Add Word' or 'Edit Word' dialogs by typing the name of the tag you want in the 'Tags' field, then pressing enter."

export default function TagsDialog(props: TagsDialogProps) {
  const {
    open,
    setOpen,
    handleClose,
    tagOptions,
    setTagOptions,
    words,
    setWords,
    setFilterModel,
    activeCollection,
    buttonsEnabled,
    setButtonsEnabled,
  } = props

  // edit fields
  const [tagToEdit, setTagToEdit] = useState<TagData | undefined>()
  const [tagToEditText, setTagToEditText] = useState<string>("")
  const [tagEditDialogOpen, setTagEditDialogOpen] = useState<boolean>(false)

  // add fields
  const [tagToAddText, setTagToAddText] = useState<string>("")
  const [tagEditDialogOpenAdd, setTagEditDialogOpenAdd] =
    useState<boolean>(false)

  // delete fields
  const [tagToDelete, setTagToDelete] = useState<TagData | undefined>()
  const [deleteTagDialogOpen, setDeleteTagDialogOpen] = useState(false)

  function handleEditButtonClicked(tag: TagData) {
    setTagToEdit(tag)
    setTagToEditText(tag.tag)
    setTagEditDialogOpen(true)
  }

  async function handleSubmitTagEdit() {
    try {
      setButtonsEnabled(false)
      const tagResponse: AxiosResponse = await putTag(tagToEdit, tagToEditText)
      if (tagResponse.status !== 200) {
        throw Error("Bad response on putTag")
      }
      const tagOptionsResponse: AxiosResponse = await getTagOptions(
        activeCollection?.id
      )
      if (tagOptionsResponse.status !== 200) {
        throw Error("Bad response on getTagOptions")
      }
      setTagOptions(tagOptionsResponse.data)
      const wordsResponse: AxiosResponse = await getWords(activeCollection?.id)
      if (wordsResponse.status !== 200) {
        throw Error("Bad response on getWords")
      }
      setWords(wordsResponse.data)
      setTagEditDialogOpen(false)
    } catch (error) {
      console.log(error)
    }
    setTagToEdit(undefined)
    setButtonsEnabled(true)
  }

  function handleCancelTagEdit() {
    setTagEditDialogOpen(false)
    setTagToEditText(tagToEdit?.tag ?? "")
    setTagToEdit(undefined)
  }

  function handleDeleteButtonClicked(tag: TagData) {
    setTagToDelete(tag)
    setDeleteTagDialogOpen(true)
  }

  function handleTagCountButtonClicked(tag: TagData) {
    setOpen(false)
    setFilterModel({
      items: [
        {
          columnField: "tags",
          operatorValue: "isAnyOf",
          value: [tag.tag],
        },
      ],
    })
  }

  async function handleSubmitDelete() {
    try {
      setButtonsEnabled(false)
      const tagResponse: AxiosResponse = await deleteTag(tagToDelete)
      if (tagResponse.status !== 204) {
        throw Error("Bad response on deleteTag")
      }
      const tagOptionsResponse: AxiosResponse = await getTagOptions(
        activeCollection?.id
      )
      if (tagOptionsResponse.status !== 200) {
        throw Error("Bad response on getTagOptions")
      }
      setTagOptions(tagOptionsResponse.data)
      const wordsResponse: AxiosResponse = await getWords(activeCollection?.id)
      if (wordsResponse.status !== 200) {
        throw Error("Bad response on getWords")
      }
      setWords(wordsResponse.data)
      setDeleteTagDialogOpen(false)
    } catch (error) {
      console.log(error)
    }
    setTagToDelete(undefined)
    setButtonsEnabled(true)
  }

  function handleCancelDelete() {
    setDeleteTagDialogOpen(false)
    setTagToDelete(undefined)
  }

  const ListItemWithWiderSecondaryAction = styled(ListItem)(({ theme }) => ({
    "&.MuiListItem-secondaryAction": {
      paddingRight: 170,
    },
  }))

  function getWordCountString(tag: TagData): string {
    let count = words.filter((w) => {
      let containsTag = false
      for (const t of w.tags) {
        if (t.id === tag.id) {
          containsTag = true
        }
      }
      return containsTag
    }).length

    return `${count + (count === 1 ? " word" : " words")}`
  }

  function handleAddButtonClicked() {
    setTagToEdit(undefined)
    setTagEditDialogOpenAdd(true)
  }

  async function handleSubmitTagAdd() {
    try {
      setButtonsEnabled(false)
      const tagResponse: AxiosResponse = await postTag(
        tagToAddText,
        activeCollection
      )
      if (tagResponse.status !== 201) {
        throw Error("Bad response on postTag")
      }
      const tagOptionsResponse: AxiosResponse = await getTagOptions(
        activeCollection?.id
      )
      if (tagOptionsResponse.status !== 200) {
        throw Error("Bad response on getTagOptions")
      }
      setTagOptions(tagOptionsResponse.data)
      setTagEditDialogOpenAdd(false)
      setTagToAddText("")
    } catch (error) {
      console.log(error)
    }
    setButtonsEnabled(true)
  }

  function handleCancelTagAdd() {
    setTagToAddText("")
    setTagEditDialogOpenAdd(false)
  }

  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Tags
          <Button
            color="primary"
            startIcon={<AddIcon style={{ fontSize: 25 }} />}
            onClick={() => handleAddButtonClicked()}
          >
            Add
          </Button>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid item sm={12}>
                {!tagOptions.length ? (
                  <Typography>{TAGS_HELPER}</Typography>
                ) : (
                  <List dense>
                    {tagOptions.map((tag: TagData) => (
                      <ListItemWithWiderSecondaryAction
                        key={tag.id}
                        disablePadding
                      >
                        <ListItemText primary={tag.tag} />
                        <ListItemSecondaryAction>
                          <Button
                            onClick={() => handleTagCountButtonClicked(tag)}
                          >
                            {getWordCountString(tag)}
                          </Button>
                          <IconButton
                            color="primary"
                            aria-label="edit"
                            onClick={() => handleEditButtonClicked(tag)}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteButtonClicked(tag)}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItemWithWiderSecondaryAction>
                    ))}
                  </List>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <TagEditDialog
        open={tagEditDialogOpen}
        tagText={tagToEditText}
        setTagText={setTagToEditText}
        handleSubmit={handleSubmitTagEdit}
        handleCancel={handleCancelTagEdit}
        buttonsEnabled={buttonsEnabled}
        title="Edit Tag"
        submitButtonText="Submit"
      />
      <TagEditDialog
        open={tagEditDialogOpenAdd}
        tagText={tagToAddText}
        setTagText={setTagToAddText}
        handleSubmit={handleSubmitTagAdd}
        handleCancel={handleCancelTagAdd}
        buttonsEnabled={buttonsEnabled}
        title="Add Tag"
        submitButtonText="Add"
      />
      <DeleteDialog
        open={deleteTagDialogOpen}
        title="Delete Tag"
        helperText="Are you sure you want to delete this tag?"
        handleSubmit={handleSubmitDelete}
        submitText="Delete"
        handleCancel={handleCancelDelete}
        cancelText="Cancel"
        buttonsEnabled={buttonsEnabled}
      />
    </div>
  )
}
