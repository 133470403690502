import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import React, { useEffect, useState } from "react"
import { Paper, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import ChartProps from "./ChartProps"
import { DateTime } from "luxon"
import { WordData } from "../model/WordData"

interface Day {
  day: string
  wordsCreated: number
  wordsFinalized: number
  wordsReviewed: number
}

const NUM_BARS_TO_DISPLAY_XS = 7
const NUM_BARS_TO_DISPLAY_SM = 14
const NUM_BARS_TO_DISPLAY_MD = 14
const NUM_BARS_TO_DISPLAY_LG = 21
const NUM_BARS_TO_DISPLAY_XL = 28

export default function DailyWordsChart(props: ChartProps) {
  const { words, title, height } = props

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const isSm = useMediaQuery(theme.breakpoints.only("sm"))
  const isMd = useMediaQuery(theme.breakpoints.only("md"))
  const isLg = useMediaQuery(theme.breakpoints.only("lg"))
  const isXl = useMediaQuery(theme.breakpoints.only("xl"))

  function getNumBarsPerBreakpoint(): number {
    if (isXs) {
      return NUM_BARS_TO_DISPLAY_XS
    } else if (isSm) {
      return NUM_BARS_TO_DISPLAY_SM
    } else if (isMd) {
      return NUM_BARS_TO_DISPLAY_MD
    } else if (isLg) {
      return NUM_BARS_TO_DISPLAY_LG
    } else if (isXl) {
      return NUM_BARS_TO_DISPLAY_XL
    } else {
      return 0
    }
  }

  function startOfDay(date: Date): DateTime {
    return DateTime.fromISO(date.toString()).startOf("day")
  }

  function dateAsString(date: DateTime): string {
    return date.toLocaleString({
      year: "2-digit",
      month: "numeric",
      day: "numeric",
    })
  }

  const numBars = getNumBarsPerBreakpoint()

  const [data, setData] = useState<Day[]>([])

  useEffect(() => {
    if (!words.length) {
      return
    }

    // set initial earliest day to tomorrow (so the loop fires in the initial iteration)
    let earliestDate = DateTime.now().startOf("day").plus({ days: 1 })

    // use reduce to calculate counts for each date
    const counts: Record<
      string,
      { created: number; finalized: number; reviewed: number }
    > = words.reduce((accumulator, word: WordData) => {
      const dateCreated = startOfDay(word.date_created)

      // backfill dates between this date created up to the last known earliest date
      for (
        let date: DateTime = dateCreated;
        date < earliestDate;
        date = date.plus({ days: 1 })
      ) {
        accumulator[dateAsString(date)] = accumulator[dateAsString(date)] || {
          created: 0,
          finalized: 0,
          reviewed: 0,
        }
      }

      // update earliestDate
      earliestDate = dateCreated

      // increment count for created
      accumulator[dateAsString(dateCreated)].created += 1

      // increment count for finalized if date exists
      if (word.date_finalized) {
        const dateFinalized = startOfDay(word.date_finalized)
        accumulator[dateAsString(dateFinalized)].finalized += 1
      }

      // increment count for reviewed if date exists
      if (word.date_last_reviewed) {
        const dateLastReviewed = startOfDay(word.date_last_reviewed)
        accumulator[dateAsString(dateLastReviewed)].reviewed += 1
      }

      return accumulator
    }, {} as Record<string, { created: number; finalized: number; reviewed: number }>)

    // Convert counts to Recharts-compatible data structure
    const days: Day[] = Object.keys(counts).map((date: string) => ({
      day: date,
      wordsCreated: counts[date].created,
      wordsFinalized: counts[date].finalized,
      wordsReviewed: counts[date].reviewed,
    }))

    setData(days)
  }, [words])

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: height,
        padding: 2,
        border: `2px solid ${theme.palette.primary.main}`,
      }}
    >
      <Typography variant="h6" color="primary">
        {title}
      </Typography>
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <YAxis width={30} />
          <Tooltip />
          <Legend />
          <Brush
            dataKey="day"
            height={50}
            stroke={theme.palette.primary.main}
            startIndex={data.length > numBars ? data.length - numBars : 0}
          />
          <Bar
            name="New words"
            dataKey="wordsCreated"
            stackId="a"
            fill={theme.palette.primary.main}
          />
          <Bar
            name="Finalized words"
            dataKey="wordsFinalized"
            stackId="a"
            fill={theme.palette.secondary.main}
          />
          <Bar
            name="Reviewed words"
            dataKey="wordsReviewed"
            stackId="a"
            fill={theme.palette.warning.main}
          />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  )
}
