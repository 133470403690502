import React from "react"
import { GridFooter, GridFooterContainer } from "@mui/x-data-grid"
import { Grid, Typography } from "@mui/material"

export default function CustomDataGridFooter() {
  return (
    <GridFooterContainer>
      <Grid
        container
        direction={{ xs: "column-reverse", sm: "row" }}
        sx={{
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Typography variant="body2" sx={{ pl: "16px" }}>
            © {new Date().getFullYear()} Word Journey LLC
          </Typography>
        </Grid>
        <Grid item>
          <GridFooter />
        </Grid>
      </Grid>
    </GridFooterContainer>
  )
}
