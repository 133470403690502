import PieChartCardProps from "./PieChartCardProps"
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
} from "recharts"
import { createTheme, Paper, Typography, useMediaQuery } from "@mui/material"
import React from "react"
import PieChartData from "./PieChartData"

const RING_THICKNESS_PERCENTAGE = 0.2
const RING_OUTER_DIAMETER_STANDARD = 130
const RING_OUTER_DIAMETER_NARROW = 80
const RING_PADDING_ANGLE = 3

export default function PieChartCard(props: PieChartCardProps) {
  const { title, subtitle, chartData, centerLabel, height, colorScheme } = props

  const theme = createTheme()
  const ringOuterDiameter = useMediaQuery(theme.breakpoints.down("sm"))
    ? RING_OUTER_DIAMETER_NARROW
    : RING_OUTER_DIAMETER_STANDARD

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: height,
        padding: 2,
        border: `2px solid ${theme.palette.primary.main}`,
      }}
    >
      <div style={{ position: "relative" }}>
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          color="primary"
          sx={{ top: "100%", position: "absolute" }}
        >
          {subtitle}
        </Typography>
      </div>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={chartData}
            dataKey="count"
            label={(d) => d.value.toLocaleString()}
            innerRadius={ringOuterDiameter * (1 - RING_THICKNESS_PERCENTAGE)}
            outerRadius={ringOuterDiameter}
            paddingAngle={RING_PADDING_ANGLE}
          >
            {chartData.map((entry: PieChartData, index: number) => (
              <Cell
                name={entry.label}
                key={`cell-${index}`}
                fill={colorScheme(entry, index)}
              />
            ))}
            <Label
              value={centerLabel}
              position="center"
              fontSize="45px"
              fill="black"
            />
          </Pie>
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Paper>
  )
}
