import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import SellOutlinedIcon from "@mui/icons-material/SellOutlined"
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined"
import React from "react"
import CustomDataGridToolbarProps from "./CustomDataGridToolbarProps"
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined"
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined"
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined"

export default function CustomDataGridToolbar(
  props: CustomDataGridToolbarProps
) {
  const {
    handleAddButtonClicked,
    handleTagsButtonClicked,
    handleCollectionsButtonClicked,
    handleFlashcardsButtonClicked,
    handleStatsButtonClicked,
    handleGalleryButtonClicked,
  } = props

  return (
    <GridToolbarContainer>
      <Button
        size="small"
        onClick={handleAddButtonClicked}
        startIcon={<AddIcon style={{ fontSize: 25 }} />}
      >
        Add
      </Button>
      <Button
        size="small"
        onClick={handleTagsButtonClicked}
        startIcon={<SellOutlinedIcon style={{ fontSize: 17 }} />}
      >
        Tags
      </Button>
      <Button
        size="small"
        onClick={handleCollectionsButtonClicked}
        startIcon={<LibraryBooksOutlinedIcon style={{ fontSize: 17 }} />}
      >
        Collections
      </Button>
      <Button
        size="small"
        onClick={handleFlashcardsButtonClicked}
        startIcon={<StyleOutlinedIcon style={{ fontSize: 19 }} />}
      >
        Flashcards
      </Button>
      <Button
        size="small"
        onClick={handleStatsButtonClicked}
        startIcon={<InsertChartOutlinedOutlinedIcon style={{ fontSize: 20 }} />}
      >
        Statistics
      </Button>
      <Button
        size="small"
        onClick={handleGalleryButtonClicked}
        startIcon={<DashboardOutlinedIcon style={{ fontSize: 19 }} />}
      >
        Gallery
      </Button>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <div style={{ flex: "1 0 0" }} />
      <GridToolbarQuickFilter debounceMs={200} />
    </GridToolbarContainer>
  )
}
