import axios, { AxiosResponse } from "axios"
import { WordData } from "../model/WordData"
import { CollectionData } from "../model/CollectionData"
import { TagData } from "../model/TagData"

export async function getPosValues(): Promise<AxiosResponse> {
  let posValuesUrl = "/parts-of-speech"
  try {
    return await axios.get(posValuesUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function postUser(
  userId: string | undefined
): Promise<AxiosResponse> {
  if (!userId) {
    return Promise.reject(Error("No user id"))
  }
  let usersUrl = "/users"
  try {
    return await axios.post(usersUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getUser(
  userId: string | undefined
): Promise<AxiosResponse> {
  if (!userId) {
    return Promise.reject(Error("No user id"))
  }
  let usersUrl = `/users/${userId}`
  try {
    return await axios.get(usersUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getGenderValues(): Promise<AxiosResponse> {
  let genderValuesUrl = "/genders"
  try {
    return await axios.get(genderValuesUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getCollection(
  collectionId: string
): Promise<AxiosResponse> {
  if (!collectionId) {
    return Promise.reject(Error("No collection id"))
  }
  let collectionUrl = `/collections/${collectionId}`
  try {
    return await axios.get(collectionUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function postCollection(
  userId: string | undefined,
  title: string,
  language1: string,
  language2: string
): Promise<AxiosResponse> {
  if (!userId) {
    return Promise.reject(Error("No user id"))
  }
  let addCollectionUrl = `/users/${userId}/collections`
  try {
    return await axios.post(addCollectionUrl, {
      userId: userId,
      title: title,
      language1: language1,
      language2: language2,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function putCollection(
  collection: CollectionData | undefined,
  title: string,
  language1: string,
  language2: string
): Promise<AxiosResponse> {
  if (!collection) {
    return Promise.reject(Error("No collection"))
  }
  if (!collection.id) {
    return Promise.reject(Error("No collection id"))
  }
  let editCollectionUrl = `/collections/${collection.id}`
  try {
    return await axios.put(editCollectionUrl, {
      id: collection.id,
      title: title,
      language1: language1,
      language2: language2,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getCollections(
  userId: string | undefined
): Promise<AxiosResponse> {
  if (!userId) {
    return Promise.reject(Error("No user id"))
  }
  let collectionsUrl = `/users/${userId}/collections`
  try {
    return await axios.get(collectionsUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function deleteWord(wordId: string): Promise<AxiosResponse> {
  if (!wordId) {
    return Promise.reject(Error("No word id"))
  }
  let wordsUrl = `/words/${wordId}`
  try {
    return await axios.delete(wordsUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getWords(
  activeCollectionId: string | undefined
): Promise<AxiosResponse> {
  if (!activeCollectionId) {
    return Promise.reject(Error("No activeCollection id"))
  }
  let wordsUrl = `/collections/${activeCollectionId}/words`
  try {
    return await axios.get(wordsUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function putWord(
  word: WordData,
  tags: string[]
): Promise<AxiosResponse> {
  if (!word.id) {
    return Promise.reject(Error("No word id"))
  }
  if (!word.collection_id) {
    return Promise.reject(Error("No collection id"))
  }
  let wordUrl = `/words/${word.id}`
  try {
    return await axios.put(wordUrl, {
      id: word.id,
      collectionId: word.collection_id,
      word: word.word || null,
      plural: word.plural || null,
      pos: word.pos || null,
      lemma: word.lemma || null,
      trans: word.trans || null,
      example: word.example || null,
      example_trans: word.example_trans || null,
      mnemonic: word.mnemonic || null,
      gender: word.gender || null,
      notes: word.notes || null,
      tags: tags,
      is_final: word.is_final,
      usage: word.usage || null,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function postWord(
  word: WordData,
  tags: string[],
  collection: CollectionData | undefined
): Promise<AxiosResponse> {
  if (!collection?.id) {
    return Promise.reject(Error("No collection id"))
  }
  let wordsUrl = `/collections/${collection?.id}/words`
  try {
    return await axios.post(wordsUrl, {
      collection: collection?.id,
      word: word.word || null,
      plural: word.plural || null,
      pos: word.pos || null,
      lemma: word.lemma || null,
      trans: word.trans || null,
      example: word.example || null,
      example_trans: word.example_trans || null,
      mnemonic: word.mnemonic || null,
      gender: word.gender || null,
      notes: word.notes || null,
      tags: tags,
      is_final: word.is_final,
      usage: word.usage,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getTagOptions(
  activeCollectionId: string | undefined
): Promise<AxiosResponse> {
  if (!activeCollectionId) {
    return Promise.reject(Error("No activeCollection id"))
  }
  let tagsUrl = `/collections/${activeCollectionId}/tags`
  try {
    return await axios.get(tagsUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function patchDefaultCollection(
  userId: string | undefined,
  collectionId: string
): Promise<any> {
  if (!userId) {
    return Promise.reject(Error("No user id"))
  }
  let defaultCollectionUrl = `/users/${userId}/default-collection`
  try {
    return await axios.patch(defaultCollectionUrl, {
      id: userId,
      collectionId: collectionId || null,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function postTag(
  tagTitle: string | undefined,
  collection: CollectionData | undefined
): Promise<AxiosResponse> {
  if (!tagTitle) {
    return Promise.reject(Error("No tag title"))
  }
  if (!collection?.id) {
    return Promise.reject(Error("No collection id"))
  }
  let tagUrl = `/collections/${collection?.id}/tags`
  try {
    return await axios.post(tagUrl, {
      collection: collection.id,
      tag: tagTitle || null,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function putTag(
  tag: TagData | undefined,
  title: string
): Promise<AxiosResponse> {
  if (!tag) {
    return Promise.reject(Error("No tag"))
  }
  if (!tag.id) {
    return Promise.reject(Error("No tag id"))
  }
  let editTagUrl = `/tags/${tag.id}`
  try {
    return await axios.put(editTagUrl, {
      id: tag.id,
      tag: title,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function deleteTag(
  tag: TagData | undefined
): Promise<AxiosResponse> {
  if (!tag) {
    return Promise.reject(Error("No tag"))
  }
  if (!tag.id) {
    return Promise.reject(Error("No tag id"))
  }
  let tagUrl = `/tags/${tag.id}`
  try {
    return await axios.delete(tagUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function deleteCollection(
  collection: CollectionData | undefined
): Promise<AxiosResponse> {
  if (!collection) {
    return Promise.reject(Error("No collection"))
  }
  if (!collection.id) {
    return Promise.reject(Error("No collection id"))
  }
  let collectionUrl = `/collections/${collection.id}`
  try {
    return await axios.delete(collectionUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function patchWordScoreReset(
  wordId: string,
  language: number
): Promise<AxiosResponse> {
  if (!wordId) {
    return Promise.reject(Error("No word id"))
  }
  if (language !== 1 && language !== 2) {
    return Promise.reject(Error("Bad language value"))
  }
  let patchWordScoreResetUrl = `/words/${wordId}/score/reset`
  try {
    return await axios.patch(patchWordScoreResetUrl, {
      id: wordId,
      language: language,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function patchWordScoreIncrement(
  wordId: string,
  language: number
): Promise<AxiosResponse> {
  if (!wordId) {
    return Promise.reject(Error("No word id"))
  }
  if (language !== 1 && language !== 2) {
    return Promise.reject(Error("Bad language value"))
  }
  let patchWordScoreIncrementUrl = `/words/${wordId}/score/increment`
  try {
    return await axios.patch(patchWordScoreIncrementUrl, {
      id: wordId,
      language: language,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}
