import * as React from "react"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import CustomCircularProgressProps from "./CustomCircularProgressProps"
import { Tooltip } from "@mui/material"

export default function CustomCircularProgress(
  props: CustomCircularProgressProps
) {
  const { value, tooltipTitle, footer } = props

  return (
    <Tooltip arrow title={tooltipTitle}>
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          direction: "column",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          value={100}
          variant="determinate"
          style={{ color: "#f8c79e", position: "absolute" }}
        />
        <CircularProgress value={value} variant="determinate" color="warning" />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1" color="inherit">
            {`${value}%`}
          </Typography>
        </Box>
        <Typography
          sx={{
            position: "absolute",
            lineHeight: 1,
            bottom: 2,
          }}
        >
          {footer}
        </Typography>
      </Box>
    </Tooltip>
  )
}
