import Userfront from "@userfront/react"
import Box from "@mui/material/Box"

const PasswordResetForm = Userfront.build({
  toolId: "baanakr",
})

export default function PasswordReset() {
  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <PasswordResetForm />
    </Box>
  )
}
