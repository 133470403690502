import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import axios from "axios"
import Userfront from "@userfront/react"

axios.defaults.baseURL =
  process.env.NODE_ENV === "production"
    ? "https://word-journey.herokuapp.com"
    : ""
axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${Userfront.accessToken()}`

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
