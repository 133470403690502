import { Stack, Switch, Typography } from "@mui/material"
import React from "react"
import { WordData } from "../model/WordData"
import { DraftSwitchProps } from "./DraftSwitchProps"

export function DraftSwitch(props: DraftSwitchProps) {
  const { word, setWord } = props

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography>Draft</Typography>
      <Switch
        checked={word.is_final}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setWord((prevState: WordData) => ({
            ...prevState,
            is_final: event.target.checked,
          }))
        }}
      />
      <Typography>Final</Typography>
    </Stack>
  )
}
