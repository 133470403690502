import { StatisticsDialogProps } from "./StatisticsDialogProps"
import {
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material"
import React from "react"
import DailyWordsChart from "../charts/DailyWordsChart"
import PieChartCard from "../charts/PieChartCard"
import PieChartData from "../charts/PieChartData"

let theme = createTheme()
const COLORS = [
  theme.palette.secondary.light,
  theme.palette.error.light,
  theme.palette.info.light,
  theme.palette.success.light,
  theme.palette.warning.light,
  theme.palette.secondary.dark,
  theme.palette.error.dark,
  theme.palette.info.dark,
  theme.palette.success.dark,
  theme.palette.warning.dark,
]
const COLORS_SCORE = ["#938ac8", "#db739b", "#fa9955", "#fb6a3c", "#db3c4c"]
const CHART_HEIGHT = 425

export function StatisticsDialog(props: StatisticsDialogProps) {
  const { open, words, handleClose, partsOfSpeech, genders, collection } = props

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth={!!words.length}>
      <DialogTitle>Statistics</DialogTitle>
      <DialogContent>
        {!words.length ? (
          <Typography>Add a word to see statistics.</Typography>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DailyWordsChart
                words={words}
                title="Words by the day"
                height={CHART_HEIGHT}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PieChartCard
                title="Parts of Speech"
                chartData={(() => {
                  return partsOfSpeech
                    .map((pos) => {
                      return {
                        label: pos
                          .split(" ")
                          .map((w) => w[0].toUpperCase() + w.slice(1))
                          .join(" "),
                        count: words.filter((w) => w.pos === pos).length,
                      }
                    })
                    .filter((d) => d.count)
                    .sort((a, b) => b.count - a.count)
                })()}
                centerLabel={words.filter((w) => w.pos).length.toLocaleString()}
                height={CHART_HEIGHT}
                colorScheme={(entry: PieChartData, index: number) =>
                  COLORS[index % COLORS.length]
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PieChartCard
                title="Noun Genders"
                chartData={(() => {
                  return genders
                    .map((gender) => {
                      return {
                        label: gender
                          .split(" ")
                          .map((w) => w[0].toUpperCase() + w.slice(1))
                          .join(" "),
                        count: words.filter((w) => w.gender === gender).length,
                      }
                    })
                    .filter((d) => d.count)
                    .sort((a, b) => b.count - a.count)
                })()}
                centerLabel={words
                  .filter((w) => w.gender)
                  .length.toLocaleString()}
                height={CHART_HEIGHT}
                colorScheme={(entry: PieChartData, index: number) => {
                  switch (entry.label.toLowerCase()) {
                    case "masculine":
                      return theme.palette.info.light
                    case "feminine":
                      return theme.palette.error.light
                    case "neuter":
                      return theme.palette.warning.light
                    case "plural":
                      return theme.palette.secondary.light
                    default:
                      return "black"
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PieChartCard
                title="Word Scores"
                subtitle={`${collection?.language1} → ${collection?.language2}`}
                chartData={(() => {
                  return [1, 2, 3, 4, 5]
                    .map((score) => {
                      return {
                        label: score.toString(),
                        count: words.filter((w) => w.score_word === score)
                          .length,
                      }
                    })
                    .filter((d) => d.count)
                })()}
                centerLabel={words
                  .filter((w) => w.score_word)
                  .length.toLocaleString()}
                height={CHART_HEIGHT}
                colorScheme={(entry: PieChartData, index: number) =>
                  COLORS_SCORE[index % COLORS_SCORE.length]
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PieChartCard
                title="Word Scores"
                subtitle={`${collection?.language2} → ${collection?.language1}`}
                chartData={(() => {
                  return [1, 2, 3, 4, 5]
                    .map((score) => {
                      return {
                        label: score.toString(),
                        count: words.filter((w) => w.score_trans === score)
                          .length,
                      }
                    })
                    .filter((d) => d.count)
                })()}
                centerLabel={words
                  .filter((w) => w.score_trans)
                  .length.toLocaleString()}
                height={CHART_HEIGHT}
                colorScheme={(entry: PieChartData, index: number) =>
                  COLORS_SCORE[index % COLORS_SCORE.length]
                }
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
