import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import React from "react"
import Dialog from "@mui/material/Dialog"
import { Button, DialogActions } from "@mui/material"
import { DeleteDialogProps } from "./DeleteDialogProps"

export default function DeleteDialog(props: DeleteDialogProps) {
  const {
    open,
    title,
    helperText,
    handleSubmit,
    submitText,
    handleCancel,
    cancelText,
    buttonsEnabled,
  } = props

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{helperText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={!buttonsEnabled}>
          {cancelText}
        </Button>
        <Button onClick={handleSubmit} disabled={!buttonsEnabled}>
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
