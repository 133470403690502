import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Chip,
  Typography,
} from "@mui/material"
import React from "react"
import { WordProfileDialogProps } from "./WordProfileDialogProps"
import { Hashicon } from "@emeraldpay/hashicon-react"
import { TagData } from "../model/TagData"

export function WordProfileDialog(props: WordProfileDialogProps) {
  const { open, word, handleClose, activeCollection } = props

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Word Profile
        <Hashicon value={word.word} size={40} />
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          gap={1}
          sx={{ width: "100%" }}
        >
          <Box display="flex">
            <Chip
              label={`Word (${activeCollection?.language1 ?? "Language 1"})`}
              color="primary"
              sx={{ mr: 1 }}
            />
            <Typography>{word.word}</Typography>
          </Box>
          <Box display="flex">
            <Chip
              label={`Word (${activeCollection?.language2 ?? "Language 2"})`}
              color="secondary"
              sx={{ mr: 1 }}
            />
            <Typography>{word.trans}</Typography>
          </Box>
          <Box display="flex">
            <Chip
              label={`Example (${activeCollection?.language1 ?? "Language 1"})`}
              color="primary"
              sx={{ mr: 1 }}
            />
            <Typography>{word.example}</Typography>
          </Box>
          <Box display="flex">
            <Chip
              label={`Example (${activeCollection?.language2 ?? "Language 2"})`}
              color="secondary"
              sx={{ mr: 1 }}
            />
            <Typography>{word.example_trans}</Typography>
          </Box>
          <Box display="flex">
            <Chip label="Part of Speech" color="success" sx={{ mr: 1 }} />
            <Typography>{word.pos}</Typography>
          </Box>
          <Box display="flex">
            <Chip label="Mnemonic" color="error" sx={{ mr: 1 }} />
            <Typography>{word.mnemonic}</Typography>
          </Box>
          <Box display="flex">
            <Chip label="Tags" color="warning" sx={{ mr: 1 }} />
            <Box display="flex" gap={1} sx={{ flexWrap: "wrap" }}>
              {word.tags.map((tag: TagData) => (
                <Chip label={tag.tag} key={tag.id} />
              ))}
            </Box>
          </Box>
          <Box display="flex">
            <Chip label="Notes" color="info" sx={{ mr: 1 }} />
            <Typography>{word.notes}</Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
