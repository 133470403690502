import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import React, { useState, useMemo, memo } from "react"
import { GalleryDialogProps } from "./GalleryDialogProps"
import { Hashicon } from "@emeraldpay/hashicon-react"
import { WordData } from "../model/WordData"
import { WordProfileDialog } from "./WordProfileDialog"

// Memoized word card component to prevent unnecessary re-renders
const WordCard = memo(({ word, onOpenProfile }: { word: WordData, onOpenProfile: (w: WordData) => void }) => {
  if (!word?.word) return null;
  
  return (
    <Tooltip arrow title={word.trans ?? ""}>
      <IconButton
        onClick={() => onOpenProfile(word)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "0.5rem",
          minWidth: "80px",
          minHeight: "100px",
          border: "1px solid lightgray",
          borderRadius: "8px",
          cursor: "pointer",
          "&:hover": { boxShadow: 3 },
        }}
      >
        <Hashicon value={word.word} size={40} />
        <Typography color="text.primary">{word.word}</Typography>
      </IconButton>
    </Tooltip>
  );
});

export function GalleryDialog(props: GalleryDialogProps) {
  const { open, words, handleClose, activeCollection } = props
  const [selectedWord, setSelectedWord] = useState<WordData | null>(null)
  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 50

  const handleOpenWordProfile = (word: WordData) => {
    setSelectedWord(word)
  }

  const handleCloseWordProfile = () => {
    setSelectedWord(null)
  }

  // Memoize the filtered words to prevent unnecessary recalculations
  const validWords = useMemo(() => 
    words.filter(w => w?.word), 
    [words]
  );

  // Get current page's words
  const currentWords = useMemo(() => {
    const startIndex = currentPage * itemsPerPage;
    return validWords.slice(startIndex, startIndex + itemsPerPage);
  }, [validWords, currentPage]);

  const totalPages = Math.ceil(validWords.length / itemsPerPage);

  return (
    <Dialog open={open} maxWidth="xl" fullWidth>
      <DialogTitle>Word Gallery</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          gap="1rem"
          sx={{ width: "100%" }}
        >
          {currentWords.map((w: WordData) => (
            <WordCard 
              key={w.id} 
              word={w} 
              onOpenProfile={handleOpenWordProfile}
            />
          ))}
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button 
            disabled={currentPage === 0}
            onClick={() => setCurrentPage(prev => prev - 1)}
          >
            Previous
          </Button>
          <Typography>
            Page {currentPage + 1} of {totalPages}
          </Typography>
          <Button 
            disabled={currentPage >= totalPages - 1}
            onClick={() => setCurrentPage(prev => prev + 1)}
          >
            Next
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>

      {selectedWord && (
        <WordProfileDialog
          open={!!selectedWord}
          word={selectedWord}
          handleClose={handleCloseWordProfile}
          activeCollection={activeCollection}
        />
      )}
    </Dialog>
  )
}
