import { TagEditDialogProps } from "./TagEditDialogProps"
import { Button, Dialog, DialogActions, TextField, Box } from "@mui/material"
import React from "react"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"

export default function TagEditDialog(props: TagEditDialogProps) {
  const {
    open,
    tagText,
    setTagText,
    handleCancel,
    handleSubmit,
    buttonsEnabled,
    title,
    submitButtonText,
  } = props

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ flexGrow: 1, pt: 1 }}>
          <TextField
            autoFocus
            fullWidth={true}
            id="tag"
            label="Tag"
            type="text"
            variant="outlined"
            value={tagText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTagText(event.target.value)
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={!buttonsEnabled}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={!buttonsEnabled}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
