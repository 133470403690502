import React, { useEffect, useMemo, useState } from "react"
import { CollectionData } from "../model/CollectionData"
import { WordData } from "../model/WordData"
import { TagData } from "../model/TagData"
import {
  DataGrid,
  getGridSingleSelectOperators,
  GridCellParams,
  GridColDef,
  GridComparatorFn,
  GridFilterItem,
  GridFilterModel,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid"
import { AxiosResponse } from "axios"
import {
  getCollection,
  getCollections,
  getGenderValues,
  getPosValues,
  getTagOptions,
  getUser,
  getWords,
  postCollection,
  postTag,
  postUser,
  postWord,
  putWord,
} from "../requests/Requests"
import { GridCellExpand } from "../components/GridCellExpand"
import { ActionButtons } from "../components/ActionButtons"
import { AppBar, Box, Chip, Stack, Tooltip } from "@mui/material"
import FlagIcon from "@mui/icons-material/Flag"
import WordDialog from "../components/WordDialog"
import { Helmet } from "react-helmet"
import { CustomAppBar } from "../components/CustomAppBar"
import CustomDataGridToolbar from "../components/CustomDataGridToolbar"
import TagsDialog from "../components/TagsDialog"
import CollectionsDialog from "../components/CollectionsDialog"
import CollectionEditDialog from "../components/CollectionEditDialog"
import Userfront from "@userfront/react"
import { Redirect } from "react-router-dom"
import { FlashcardsDialog } from "../components/FlashcardsDialog"
import { StatisticsDialog } from "../components/StatisticsDialog"
import CustomDataGridFooter from "../components/CustomDataGridFooter"
import { Hashicon } from "@emeraldpay/hashicon-react"
import { WordProfileDialog } from "../components/WordProfileDialog"
import { GalleryDialog } from "../components/GalleryDialog"

export const DEFAULT_WORD_DATA: WordData = {
  id: "",
  collection_id: "",
  word: "",
  trans: "",
  date_created: new Date(), //TODO: maybe make this optional
  date_updated: new Date(),
  example: "",
  example_trans: "",
  gender: "",
  lemma: "",
  mnemonic: "",
  notes: "",
  plural: "",
  pos: "",
  tags: [],
  is_final: false,
  usage: "",
  score_word: 0,
  score_trans: 0,
  date_finalized: undefined,
  date_last_reviewed: undefined,
}

export function isStringInArray(
  searchString: string,
  array: string[]
): boolean {
  return array.some((item) => item.toLowerCase() === searchString.toLowerCase())
}

export function getExistingTag(searchString: string, array: string[]): string {
  return array.find(
    (item) => item.toLowerCase() === searchString.toLowerCase()
  ) as string
}

export default function Dashboard() {
  const [userFound, setUserFound] = useState<boolean>(false)

  // collection state management
  const [defaultCollectionId, setDefaultCollectionId] = useState<
    string | undefined
  >(undefined)
  const [activeCollection, setActiveCollection] = useState<
    CollectionData | undefined
  >(undefined)

  // data grid
  const [words, setWords] = useState<WordData[]>([])
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
  const [filterModel, setFilterModel] = useState<GridFilterModel>()

  // tags dialog
  const [tagsDialogIsOpen, setTagsDialogIsOpen] = useState<boolean>(false)

  // collections dialog
  const [collections, setCollections] = useState<CollectionData[]>([])
  const [collectionsDialogIsOpen, setCollectionsDialogIsOpen] =
    useState<boolean>(false)

  // selects
  const [posValues, setPosValues] = useState<string[]>([])
  const [genderValues, setGenderValues] = useState<string[]>([])
  const [tagOptions, setTagOptions] = useState<TagData[]>([])

  // add dialog
  const [wordToAdd, setWordToAdd] = useState<WordData>({
    ...DEFAULT_WORD_DATA,
  })
  const [tagSelectionForAdd, setTagSelectionForAdd] = useState<string[]>([])
  const [uncommittedTagForAdd, setUncommittedTagForAdd] = useState<string>("")
  const [addWordDialogIsOpen, setAddWordDialogIsOpen] = useState<boolean>(false)

  // edit dialog
  const [wordToEdit, setWordToEdit] = useState<WordData>({
    ...DEFAULT_WORD_DATA,
  })
  const [tagSelectionForEdit, setTagSelectionForEdit] = useState<string[]>([])
  const [uncommittedTagForEdit, setUncommittedTagForEdit] = useState<string>("")
  const [editWordDialogIsOpen, setEditWordDialogIsOpen] =
    useState<boolean>(false)

  // add collection dialog
  const [collectionEditDialogOpenAdd, setCollectionEditDialogOpenAdd] =
    useState<boolean>(false)
  const [collectionToAddTitle, setCollectionToAddTitle] = useState<string>("")
  const [collectionToAddLanguage1, setCollectionToAddLanguage1] =
    useState<string>("")
  const [collectionToAddLanguage2, setCollectionToAddLanguage2] =
    useState<string>("")

  // flashcards dialog
  const [flashcardsDialogIsOpen, setFlashcardsDialogIsOpen] =
    useState<boolean>(false)

  // stats dialog
  const [statsDialogIsOpen, setStatsDialogIsOpen] = useState<boolean>(false)

  // button state control
  const [buttonsEnabled, setButtonsEnabled] = useState<boolean>(true)

  // table loading state
  const [tableIsLoading, setTableIsLoading] = useState<boolean>(true)

  // word profile dialog
  const [wordProfileDialogIsOpen, setWordProfileDialogIsOpen] =
    useState<boolean>(false)
  const [wordProfileWord, setWordProfileWord] = useState<WordData>({
    ...DEFAULT_WORD_DATA,
  })

  // gallery dialog
  const [galleryDialogIsOpen, setGalleryDialogIsOpen] = useState<boolean>(false)

  async function handleSubmitAddCollection() {
    try {
      const collectionResponse: AxiosResponse = await postCollection(
        Userfront.user.userUuid,
        collectionToAddTitle,
        collectionToAddLanguage1,
        collectionToAddLanguage2
      )
      if (collectionResponse.status !== 201) {
        throw Error("Bad response on postCollection")
      }
      setActiveCollection(collectionResponse.data)
      const collectionsResponse: AxiosResponse = await getCollections(
        Userfront.user.userUuid
      )
      if (collectionsResponse.status !== 200) {
        throw Error("Bad response on getCollections")
      }
      setCollections(collectionsResponse.data)
      setCollectionToAddTitle("")
      setCollectionToAddLanguage1("")
      setCollectionToAddLanguage2("")
      setCollectionEditDialogOpenAdd(false)
    } catch (error) {
      console.log(error)
    }
  }

  function handleTagsButtonClicked() {
    setTagsDialogIsOpen(true)
  }

  function handleCloseTags() {
    setTagsDialogIsOpen(false)
  }

  function handleCloseCollections() {
    setCollectionsDialogIsOpen(false)
  }

  async function handleCloseFlashcards() {
    try {
      setFlashcardsDialogIsOpen(false)

      // need to re-get the words here as the flashcard dialog could have modified the score values
      const wordsResponse: AxiosResponse = await getWords(activeCollection?.id)
      if (wordsResponse.status !== 200) {
        throw Error("Bad response on getWords")
      }
      setWords(wordsResponse.data)
    } catch (error) {
      console.log(error)
    }
  }

  function handleCloseStatsSelection() {
    setStatsDialogIsOpen(false)
  }

  function handleAddButtonClicked() {
    setAddWordDialogIsOpen(true)
  }

  function handleCollectionsButtonClicked() {
    setCollectionsDialogIsOpen(true)
  }

  function handleFlashcardsButtonClicked() {
    setFlashcardsDialogIsOpen(true)
  }

  function handleStatsButtonClicked() {
    setStatsDialogIsOpen(true)
  }

  function handleCloseWordProfileDialog() {
    setWordProfileDialogIsOpen(false)
  }

  function handleGalleryButtonClicked() {
    setGalleryDialogIsOpen(true)
  }

  function handleCloseGalleryDialog() {
    setGalleryDialogIsOpen(false)
  }

  const tagsSortComparator: GridComparatorFn<any> = (
    tags1: TagData[],
    tags2: TagData[]
  ) => {
    return tags1.length - tags2.length
  }

  const tagsFilterOperators = getGridSingleSelectOperators()
    .filter((operator) => operator.value === "isAnyOf")
    .map((operator) => {
      const newOperator = { ...operator }
      newOperator.getApplyFilterFn = (
        filterItem: GridFilterItem,
        column: GridColDef
      ) => {
        return (params: GridCellParams<WordData>): boolean => {
          let isOk = true
          filterItem?.value?.forEach((fv: any) => {
            isOk =
              isOk &&
              params.row.tags.map((tag: TagData) => tag.tag).includes(fv)
          })
          return isOk
        }
      }
      return newOperator
    })

  const tagsGetApplyQuickFilterFn = (value: string) => {
    return (params: GridCellParams<WordData>): boolean => {
      let isOk = false
      for (const tag of params.row.tags) {
        if (tag.tag.toLowerCase().includes(value.toLowerCase())) {
          isOk = true
          break
        }
      }
      return isOk
    }
  }

  function renderCellExpand(params: GridRenderCellParams<string>) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    )
  }

  function renderLocaleDateString(date: Date) {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
      hour12: false,
    }
    return date.toLocaleString(undefined, options)
  }

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 80,
      filterable: false,
      getApplyQuickFilterFn: undefined,
      cellClassName: (params) => {
        return "no-left-padding" // Return a class name that will be targeted by the sx prop for styling
      },
      renderCell: (params) => {
        return (
          <ActionButtons
            word={params.row}
            setWordToEdit={setWordToEdit}
            setEditWordDialogIsOpen={setEditWordDialogIsOpen}
            setTagSelectionForEdit={setTagSelectionForEdit}
            setWordProfileDialogIsOpen={setWordProfileDialogIsOpen}
            setWordProfileWord={setWordProfileWord}
          />
        )
      },
    },
    {
      field: "is_final",
      headerName: "Draft",
      type: "boolean",
      width: 60,
      align: "center",
      hide: true,
      getApplyQuickFilterFn: undefined,
      valueGetter: ({ value }) => !value, // reversing this b/c the purpose of the column is to highlight drafts, not finals
      renderCell: (params: GridRenderCellParams<WordData>) => {
        return (
          <div>
            {!params.row.is_final && (
              <Tooltip title="Draft" arrow>
                <FlagIcon htmlColor="#f50057" />
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      field: "lexigem",
      headerName: "LexiGem",
      width: 70,
      editable: false,
      renderCell: (params: GridRenderCellParams<WordData>) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          {params.row.word && <Hashicon value={params.row.word} size={30} />}
        </Box>
      ),
    },
    {
      field: "word",
      headerName: `Word (${activeCollection?.language1 ?? "Language 1"})`,
      width: 150,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "trans",
      headerName: `Word (${activeCollection?.language2 ?? "Language 2"})`,
      width: 150,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "plural",
      headerName: "Plural",
      width: 150,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "pos",
      headerName: "Part of Speech",
      type: "singleSelect",
      width: 150,
      editable: false,
      valueOptions: posValues,
      renderCell: renderCellExpand,
    },
    {
      field: "gender",
      headerName: "Gender",
      type: "singleSelect",
      width: 100,
      editable: false,
      valueOptions: genderValues,
      renderCell: renderCellExpand,
    },
    {
      field: "mnemonic",
      headerName: "Mnemonic",
      width: 200,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "example",
      headerName: `Example (${activeCollection?.language1 ?? "Language 1"})`,
      width: 300,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "example_trans",
      headerName: `Example (${activeCollection?.language2 ?? "Language 2"})`,
      width: 300,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 300,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "lemma",
      headerName: "Lemma",
      width: 100,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "tags",
      headerName: "Tags",
      type: "singleSelect",
      width: 300,
      editable: false,
      valueOptions: tagOptions.map((t) => t.tag),
      getApplyQuickFilterFn: tagsGetApplyQuickFilterFn,
      renderCell: (params: GridRenderCellParams<WordData>) => (
        <Stack direction="row" spacing={0.25}>
          {params.row.tags.map((tag: TagData) => (
            <Chip label={tag.tag} key={tag.id} />
          ))}
        </Stack>
      ),
      sortComparator: tagsSortComparator,
      filterOperators: tagsFilterOperators,
    },
    {
      field: "usage",
      headerName: "Usage",
      width: 150,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "score_word",
      headerName: "Score (→)",
      width: 90,
      editable: false,
      type: "singleSelect",
      valueOptions: [1, 2, 3, 4, 5],
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "score_trans",
      headerName: "Score (←)",
      width: 90,
      editable: false,
      type: "singleSelect",
      valueOptions: [1, 2, 3, 4, 5],
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "date_created",
      headerName: "Added On",
      width: 175,
      editable: false,
      getApplyQuickFilterFn: undefined,
      valueFormatter: ({ value }) => renderLocaleDateString(new Date(value)),
      type: "date",
    },
    {
      field: "date_updated",
      headerName: "Updated On",
      width: 175,
      editable: false,
      getApplyQuickFilterFn: undefined,
      valueFormatter: ({ value }) => renderLocaleDateString(new Date(value)),
      type: "date",
    },
    {
      field: "date_finalized",
      headerName: "Finalized On",
      width: 175,
      editable: false,
      getApplyQuickFilterFn: undefined,
      valueFormatter: ({ value }) =>
        value ? renderLocaleDateString(new Date(value)) : "",
      type: "date",
    },
    {
      field: "date_last_reviewed",
      headerName: "Last Reviewed On",
      width: 175,
      editable: false,
      getApplyQuickFilterFn: undefined,
      valueFormatter: ({ value }) =>
        value ? renderLocaleDateString(new Date(value)) : "",
      type: "date",
    },
  ]

  function clearFieldsAdd() {
    let emptyWord: WordData = {
      ...DEFAULT_WORD_DATA,
    }
    setWordToAdd(emptyWord)
    setTagSelectionForAdd([])
    setUncommittedTagForAdd("")
  }

  function clearFieldsEdit() {
    let emptyWord: WordData = {
      ...DEFAULT_WORD_DATA,
    }
    setWordToEdit(emptyWord)
    setTagSelectionForEdit([])
    setUncommittedTagForEdit("")
  }

  const addWordModal = useMemo(() => {
    async function handleSubmitForAdd(
      updatedWord: WordData,
      updatedTags: string[]
    ) {
      try {
        setButtonsEnabled(false)

        // make a copy of the tag selection so as not to operate on the useState itself (leads to rendering issue in the tags field)
        const tagSelection = [...updatedTags]

        const tagOptionsStrings: string[] = tagOptions.map((t) => t.tag)

        // check to see if there is an uncommitted tag in the textfield and if so, if it exists in the tag selection array
        if (
          uncommittedTagForAdd &&
          !isStringInArray(uncommittedTagForAdd, tagSelection)
        ) {
          if (isStringInArray(uncommittedTagForAdd, tagOptionsStrings)) {
            const existingTag = getExistingTag(
              uncommittedTagForAdd,
              tagOptionsStrings
            )
            tagSelection.push(existingTag)
          } else {
            tagSelection.push(uncommittedTagForAdd)
          }
        }

        // create any new tags that don't already exist
        for (const tag of tagSelection) {
          // first check if this tag is already in the list of tag options
          if (!isStringInArray(tag, tagOptionsStrings)) {
            // if not, post the new tag
            const tagResponse: AxiosResponse = await postTag(
              tag,
              activeCollection
            )
            if (tagResponse.status !== 201) {
              throw Error("Bad response on postTag")
            }
          }
        }

        // refresh the tag options
        const tagOptionsResponse: AxiosResponse = await getTagOptions(
          activeCollection?.id
        )
        if (tagOptionsResponse.status !== 200) {
          throw Error("Bad response on getTagOptions")
        }
        setTagOptions(tagOptionsResponse.data)

        // post the word
        const wordResponse: AxiosResponse = await postWord(
          updatedWord,
          tagSelection,
          activeCollection
        )
        if (wordResponse.status === 207) {
          //TODO: display an error dialog that tells the user which tags failed to be associated
          console.log(wordResponse.data)
          throw Error("Some tags failed to be associated")
        } else if (wordResponse.status !== 201) {
          throw Error("Bad response on postWord")
        }

        // refresh the word list
        const wordsResponse: AxiosResponse = await getWords(
          activeCollection?.id
        )
        if (wordsResponse.status !== 200) {
          throw Error("Bad response on getWords")
        }
        setWords(wordsResponse.data)

        // close the dialog
        setAddWordDialogIsOpen(false)
      } catch (error) {
        console.log(error)
      }
      clearFieldsAdd()
      setButtonsEnabled(true)
    }

    function handleCancelForAdd() {
      clearFieldsAdd()
      setAddWordDialogIsOpen(false)
    }

    return (
      <WordDialog
        word={wordToAdd}
        open={addWordDialogIsOpen}
        setOpen={setAddWordDialogIsOpen}
        title="Add Word"
        posValues={posValues}
        genderValues={genderValues}
        handleSubmit={handleSubmitForAdd}
        submitText="Add"
        handleCancel={handleCancelForAdd}
        cancelText="Cancel"
        tagOptions={tagOptions}
        setTagOptions={setTagOptions}
        activeCollection={activeCollection}
        tagSelection={tagSelectionForAdd}
        buttonsEnabled={buttonsEnabled}
        setButtonsEnabled={setButtonsEnabled}
        setUncommittedTag={setUncommittedTagForAdd}
        showDeleteIcon={false}
        setWords={setWords}
      />
    )
  }, [
    wordToAdd,
    addWordDialogIsOpen,
    posValues,
    genderValues,
    tagOptions,
    activeCollection,
    tagSelectionForAdd,
    buttonsEnabled,
    uncommittedTagForAdd,
  ])

  const editWordModal = useMemo(() => {
    async function handleSubmitEdit(
      updatedWord: WordData,
      updatedTags: string[]
    ) {
      try {
        setButtonsEnabled(false)

        // make a copy of the tag selection so as not to operate on the useState itself (leads to rendering issue in the tags field)
        const tagSelection = [...updatedTags]

        const tagOptionsStrings: string[] = tagOptions.map((t) => t.tag)

        // check to see if there is an uncommitted tag in the textfield and if so, if it exists in the tag selection array
        if (
          uncommittedTagForEdit &&
          !isStringInArray(uncommittedTagForEdit, tagSelection)
        ) {
          if (isStringInArray(uncommittedTagForEdit, tagOptionsStrings)) {
            const existingTag = getExistingTag(
              uncommittedTagForEdit,
              tagOptionsStrings
            )
            tagSelection.push(existingTag)
          } else {
            tagSelection.push(uncommittedTagForEdit)
          }
        }

        // create any new tags that don't already exist
        for (const tag of tagSelection) {
          // first check if this tag is already in the list of tag options
          if (!isStringInArray(tag, tagOptionsStrings)) {
            // if not, post the new tag
            const tagResponse: AxiosResponse = await postTag(
              tag,
              activeCollection
            )
            if (tagResponse.status !== 201) {
              throw Error("Bad response on postTag")
            }
          }
        }

        // refresh the tag options
        const tagOptionsResponse: AxiosResponse = await getTagOptions(
          activeCollection?.id
        )
        if (tagOptionsResponse.status !== 200) {
          throw Error("Bad response on getTagOptions")
        }
        setTagOptions(tagOptionsResponse.data)

        // put the word
        const wordResponse: AxiosResponse = await putWord(
          updatedWord,
          tagSelection
        )
        if (wordResponse.status === 207) {
          //TODO: display an error dialog that tells the user which tags failed to be associated
          console.log(wordResponse.data)
          throw Error("Some tags failed to be associated")
        } else if (wordResponse.status !== 200) {
          throw Error("Bad response on putWord")
        }

        // refresh the word list
        const wordsResponse: AxiosResponse = await getWords(
          activeCollection?.id
        )
        if (wordsResponse.status !== 200) {
          throw Error("Bad response on getWords")
        }
        setWords(wordsResponse.data)

        // close the dialog
        setEditWordDialogIsOpen(false)
      } catch (error) {
        console.log(error)
      }
      clearFieldsEdit()
      setButtonsEnabled(true)
    }

    function handleCancelEdit() {
      setEditWordDialogIsOpen(false)
      clearFieldsEdit()
    }

    return (
      <WordDialog
        word={wordToEdit}
        open={editWordDialogIsOpen}
        setOpen={setEditWordDialogIsOpen}
        title="Edit Word"
        posValues={posValues}
        genderValues={genderValues}
        handleSubmit={handleSubmitEdit}
        submitText="Submit"
        handleCancel={handleCancelEdit}
        cancelText="Cancel"
        tagOptions={tagOptions}
        setTagOptions={setTagOptions}
        activeCollection={activeCollection}
        tagSelection={tagSelectionForEdit}
        buttonsEnabled={buttonsEnabled}
        setButtonsEnabled={setButtonsEnabled}
        setUncommittedTag={setUncommittedTagForEdit}
        showDeleteIcon={true}
        setWords={setWords}
      />
    )
  }, [
    wordToEdit,
    editWordDialogIsOpen,
    posValues,
    genderValues,
    tagOptions,
    activeCollection,
    tagSelectionForEdit,
    buttonsEnabled,
    uncommittedTagForEdit,
  ])

  useEffect(() => {
    async function loadOptionsForSelectDialogs() {
      try {
        const posValuesResponse: AxiosResponse = await getPosValues()
        if (posValuesResponse.status === 200) {
          setPosValues(posValuesResponse.data.pos)
        }
        const genderValuesResponse: AxiosResponse = await getGenderValues()
        if (genderValuesResponse.status === 200) {
          setGenderValues(genderValuesResponse.data.genders)
        }
      } catch (error: any) {
        console.error(`unexpected error: ${JSON.stringify(error)}`)
      }
    }

    loadOptionsForSelectDialogs().catch((e) => console.error(e))
  }, [])

  useEffect(() => {
    async function loadUser() {
      try {
        const getUserResponse: AxiosResponse = await getUser(
          Userfront.user.userUuid
        )

        //TODO: need to check if a 3xx response could come back here instead of a 200 for 'not modified'
        if (getUserResponse.status === 200) {
          setUserFound(true)
        }
      } catch (error: any) {
        if (error.response?.status === 404) {
          try {
            const postUserResponse: AxiosResponse = await postUser(
              Userfront.user.userUuid
            )
            if (postUserResponse.status === 201) {
              setUserFound(true)
            }
          } catch (error) {
            console.error(`unexpected error: ${JSON.stringify(error)}`)
          }
        } else {
          console.error(`Unexpected error`)
        }
      }
    }

    loadUser().catch((e) => console.error(e))
  }, [])

  useEffect(() => {
    async function loadCollections() {
      try {
        if (userFound && Userfront.user.userUuid) {
          const collectionsResponse: AxiosResponse = await getCollections(
            Userfront.user.userUuid
          )
          if (collectionsResponse.status !== 200) {
            throw Error("Bad response on getCollections")
          }
          const collections: CollectionData[] = collectionsResponse.data
          setCollections(collections)
          if (collections.length === 0) {
            setCollectionEditDialogOpenAdd(true)
          } else if (collections.length === 1) {
            setActiveCollection(collections[0])
          } else {
            const getUserResponse: AxiosResponse = await getUser(
              Userfront.user.userUuid
            )
            const defaultCollectionId = getUserResponse.data.default_collection
            setDefaultCollectionId(defaultCollectionId)
            if (defaultCollectionId) {
              const collectionResponse: AxiosResponse = await getCollection(
                defaultCollectionId
              )
              const activeCollection = collectionResponse.data
              setActiveCollection(activeCollection)
            } else {
              setActiveCollection(collections[0])
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadCollections().catch((error) => {
      console.log(error)
    })
  }, [userFound])

  useEffect(() => {
    async function loadActiveCollection() {
      try {
        if (activeCollection && activeCollection.id) {
          const wordsResponse: AxiosResponse = await getWords(
            activeCollection?.id
          )
          setWords(wordsResponse.data)
          const tagsResponse: AxiosResponse = await getTagOptions(
            activeCollection?.id
          )
          setTagOptions(tagsResponse.data)
          setTableIsLoading(false)
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadActiveCollection().catch((error) => {
      console.log(error)
    })
  }, [activeCollection])

  if (!Userfront.accessToken()) {
    return <Redirect to={{ pathname: "/home" }} />
  }

  return (
    <div>
      <Helmet>
        <title>VocabFlow</title>
      </Helmet>
      <AppBar position="relative">
        <CustomAppBar words={words} />
      </AppBar>
      <div style={{ width: "100%", height: "90vh" }}>
        <DataGrid
          rows={words}
          columns={columns}
          components={{
            Toolbar: CustomDataGridToolbar,
            Footer: CustomDataGridFooter,
          }}
          componentsProps={{
            toolbar: {
              handleAddButtonClicked: handleAddButtonClicked,
              handleTagsButtonClicked: handleTagsButtonClicked,
              handleCollectionsButtonClicked: handleCollectionsButtonClicked,
              handleFlashcardsButtonClicked: handleFlashcardsButtonClicked,
              handleStatsButtonClicked: handleStatsButtonClicked,
              handleGalleryButtonClicked: handleGalleryButtonClicked,
            },
          }}
          density={"compact"}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel)
          }}
          selectionModel={selectionModel}
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: "date_updated", sort: "desc" }],
            },
          }}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          localeText={{
            noRowsLabel: tableIsLoading
              ? "Loading..."
              : "Add a word by clicking the 'Add' button in the toolbar.",
          }}
          sx={{
            "& .no-left-padding": {
              paddingLeft: 0, // Set left padding to 0
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none", // Hide the vertical separators in the header
            },
          }}
        />
      </div>
      {addWordModal}
      {editWordModal}
      <TagsDialog
        open={tagsDialogIsOpen}
        setOpen={setTagsDialogIsOpen}
        handleClose={handleCloseTags}
        tagOptions={tagOptions}
        setTagOptions={setTagOptions}
        words={words}
        setWords={setWords}
        setFilterModel={setFilterModel}
        activeCollection={activeCollection}
        buttonsEnabled={buttonsEnabled}
        setButtonsEnabled={setButtonsEnabled}
      />
      <CollectionsDialog
        open={collectionsDialogIsOpen}
        setOpen={setCollectionsDialogIsOpen}
        handleClose={handleCloseCollections}
        collections={collections}
        setCollections={setCollections}
        activeCollection={activeCollection}
        setActiveCollection={setActiveCollection}
        defaultCollectionId={defaultCollectionId}
        setDefaultCollectionId={setDefaultCollectionId}
        buttonsEnabled={buttonsEnabled}
        setButtonsEnabled={setButtonsEnabled}
        setTableIsLoading={setTableIsLoading}
      />
      <CollectionEditDialog
        open={collectionEditDialogOpenAdd}
        title={collectionToAddTitle}
        setTitle={setCollectionToAddTitle}
        language1={collectionToAddLanguage1}
        setLanguage1={setCollectionToAddLanguage1}
        language2={collectionToAddLanguage2}
        setLanguage2={setCollectionToAddLanguage2}
        dialogTitle="Add Collection"
        submitButtonText="Add"
        handleCancel={undefined}
        handleSubmit={handleSubmitAddCollection}
        buttonsEnabled={buttonsEnabled}
      />
      <FlashcardsDialog
        open={flashcardsDialogIsOpen}
        words={words}
        selectedWords={words.filter((w) => selectionModel.includes(w.id))}
        handleClose={handleCloseFlashcards}
        collection={activeCollection}
      />
      <StatisticsDialog
        open={statsDialogIsOpen}
        words={words}
        handleClose={handleCloseStatsSelection}
        partsOfSpeech={posValues}
        genders={genderValues}
        collection={activeCollection}
      />
      <WordProfileDialog
        open={wordProfileDialogIsOpen}
        word={wordProfileWord}
        handleClose={handleCloseWordProfileDialog}
        activeCollection={activeCollection}
      />
      <GalleryDialog
        open={galleryDialogIsOpen}
        words={words}
        handleClose={handleCloseGalleryDialog}
        activeCollection={activeCollection}
      />
    </div>
  )
}
