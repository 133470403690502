import { CollectionEditDialogProps } from "./CollectionEditDialogProps"
import React from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Grid,
  TextField,
} from "@mui/material"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"

export default function CollectionEditDialog(props: CollectionEditDialogProps) {
  const {
    open,
    dialogTitle,
    submitButtonText,
    title,
    setTitle,
    language1,
    language2,
    setLanguage1,
    setLanguage2,
    handleSubmit,
    handleCancel,
    buttonsEnabled,
  } = props

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Box sx={{ flexGrow: 1, pt: 1 }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <TextField
                fullWidth={true}
                id="title"
                label="Title"
                type="text"
                variant="outlined"
                value={title}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTitle(event.target.value)
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth={true}
                id="language1"
                label="Language 1"
                type="text"
                variant="outlined"
                value={language1}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLanguage1(event.target.value)
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth={true}
                id="language2"
                label="Language 2"
                type="text"
                variant="outlined"
                value={language2}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLanguage2(event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {typeof handleCancel !== "undefined" && (
          <Button onClick={handleCancel} disabled={!buttonsEnabled}>
            Cancel
          </Button>
        )}
        <Button onClick={handleSubmit} disabled={!buttonsEnabled}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
