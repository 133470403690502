import { Box, Tooltip, Typography } from "@mui/material"
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles"
import FlagIcon from "@mui/icons-material/Flag"
import React from "react"
import { FlashcardProps } from "./FlashcardProps"

let theme = createTheme()
theme = responsiveFontSizes(theme)

export default function Flashcard(props: FlashcardProps) {
  const { word, is_final, pos, example, plural, gender, score } = props

  const FLAG_SIZE = 40

  const getGenderSymbol = (gender: string) => {
    switch (gender) {
      case "masculine":
        return "♂"
      case "feminine":
        return "♀"
      case "neuter":
        return "⚲"
      case "plural":
        return "(plural)"
      default:
        return ""
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            position: "absolute",
            top: "8px",
            left: "8px",
          }}
        >
          {pos} {getGenderSymbol(gender)}
        </Typography>
        <Tooltip title="Score" arrow>
          <Typography
            variant="h5"
            component="h5"
            sx={{
              position: "absolute",
              top: "8px",
              right: "12px",
            }}
          >
            {score}
          </Typography>
        </Tooltip>
        {!is_final && (
          <Tooltip title="Draft" arrow>
            <FlagIcon
              style={{
                fontSize: FLAG_SIZE,
                position: "absolute",
                top: "8px",
                color: "#f50057",
              }}
            />
          </Tooltip>
        )}
        <Typography
          variant="h4"
          component="h4"
          sx={{
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
        >
          {word}
        </Typography>
        <Typography variant="h5" component="h5">
          {plural && <i>Pl. </i>}
          {plural}
        </Typography>
        <Typography
          variant="h5"
          component="h5"
          sx={{
            position: "absolute",
            bottom: "8px",
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
        >
          {example}
        </Typography>
      </Box>
    </ThemeProvider>
  )
}
