import Userfront from "@userfront/react"
import Box from "@mui/material/Box"
import { Switch, Route, Link } from "react-router-dom"
import SignUp from "./SignUp"

const LoginForm = Userfront.build({
  toolId: "dkklkbb",
})

export default function Home() {
  return (
    <div>
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <LoginForm />
        <nav>
          <Link to="/signup">Don't have an account? Sign up</Link>
        </nav>
      </Box>
      <Switch>
        <Route path="/signup">
          <SignUp />
        </Route>
      </Switch>
    </div>
  )
}
