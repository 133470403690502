import React from "react"
import { CustomLinearProgressProps } from "./CustomLinearProgressProps"
import { Box, LinearProgress, Tooltip, Typography } from "@mui/material"
import { WordData } from "../model/WordData"

export default function CustomLinearProgress(props: CustomLinearProgressProps) {
  const { words } = props
  const numFinal = words.filter((word: WordData) => word.is_final).length
  const progress = words.length ? (numFinal / words.length) * 100 : 0

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        gap: 8,
      }}
    >
      <Tooltip arrow title={`${numFinal} finalized words in this collection`}>
        <Typography variant="h6" color="inherit">
          {numFinal}
        </Typography>
      </Tooltip>
      <Tooltip
        arrow
        title={`${numFinal} finalized words out of ${
          words.length
        } (${Math.round(progress)}%)`}
      >
        <LinearProgress
          variant="determinate"
          color="warning"
          value={words.length ? progress : 0}
          sx={{
            width: {
              xs: 75,
              sm: 150,
            },
          }}
        />
      </Tooltip>
      <Tooltip arrow title={`${words.length} total words in this collection`}>
        <Typography variant="h6" color="inherit">
          {words.length}
        </Typography>
      </Tooltip>
    </Box>
  )
}
