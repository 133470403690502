import React from "react"
import { useTheme } from "@mui/material/styles"
import { Grid, IconButton, useMediaQuery } from "@mui/material"
import { FlashcardControlsProps } from "./FlashcardControlsProps"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined"
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined"

const ICON_SIZE = 70
const ICON_SIZE_NARROW_SCREEN = 50

export default function FlashcardControls(props: FlashcardControlsProps) {
  const {
    count,
    currentCard,
    controlRef,
    handleThumbsDownButtonClicked,
    handleThumbsUpButtonClicked,
  } = props

  const theme = useTheme()
  const isNarrowScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const responsiveIconTheme = {
    fontSize: isNarrowScreen ? ICON_SIZE_NARROW_SCREEN : ICON_SIZE,
  }

  function handleBackButtonClicked() {
    controlRef.current.prevCard()
  }

  function handleForwardButtonClicked() {
    controlRef.current.nextCard()
  }

  return (
    <Grid
      container
      direction="row"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      wrap="nowrap"
    >
      <Grid item>
        <IconButton
          color="primary"
          aria-label="previous"
          onClick={handleBackButtonClicked}
        >
          <ArrowBackIcon style={responsiveIconTheme} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color="primary"
          aria-label="previous"
          onClick={handleThumbsDownButtonClicked}
        >
          <ThumbDownAltOutlinedIcon style={responsiveIconTheme} />
        </IconButton>
      </Grid>
      <Grid item>
        {currentCard}/{count}
      </Grid>
      <Grid item>
        <IconButton
          color="primary"
          aria-label="previous"
          onClick={handleThumbsUpButtonClicked}
        >
          <ThumbUpAltOutlinedIcon style={responsiveIconTheme} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color="primary"
          aria-label="next"
          onClick={handleForwardButtonClicked}
        >
          <ArrowForwardIcon style={responsiveIconTheme} />
        </IconButton>
      </Grid>
    </Grid>
  )
}
