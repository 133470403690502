import Userfront from "@userfront/react"
import Box from "@mui/material/Box"
import { Switch, Route, Link } from "react-router-dom"
import Home from "./Home"

const SignupForm = Userfront.build({
  toolId: "orrkrnb",
})

export default function SignUp() {
  return (
    <div>
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <SignupForm />
        <nav>
          <Link to="/home">Already have an account? Sign in</Link>
        </nav>
      </Box>
      <Switch>
        <Route path="/home">
          <Home />
        </Route>
      </Switch>
    </div>
  )
}
