import "./App.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Userfront from "@userfront/react"
import Dashboard from "./routes/Dashboard"
import SignUp from "./routes/SignUp"
import Home from "./routes/Home"
import PasswordReset from "./routes/PasswordReset"

Userfront.init("6nz94qmb")

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/reset">
          <PasswordReset />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}
