import { IconButton, Tooltip, Typography, Toolbar } from "@mui/material"
import AirIcon from "@mui/icons-material/Air"
import LogoutIcon from "@mui/icons-material/Logout"
import React from "react"
import { CustomAppBarProps } from "./CustomAppBarProps"
import Userfront from "@userfront/react"
import CustomLinearProgress from "./CustomLinearProgress"
import CustomCircularProgress from "./CustomCircularProgress"

const MAXIMUM_SCORE = 5

export function weightedScore(scores: number[]): number {
  if (!scores.length) {
    return 0
  }
  const average =
    scores.reduce((acc: number, val: number) => acc + val, 0) / scores.length
  return Math.round((average * 100) / MAXIMUM_SCORE)
}

export function CustomAppBar(props: CustomAppBarProps) {
  const { words } = props
  const overallScoreLanguage1To2: number = words.length
    ? weightedScore(words.map((w) => w.score_word))
    : 0
  const overallScoreLanguage2To1: number = words.length
    ? weightedScore(words.map((w) => w.score_trans))
    : 0

  function handleLogout() {
    Userfront.logout()
  }

  return (
    <Toolbar
      sx={{
        justifyContent: "space-between",
        gap: {
          xs: 1,
          sm: 3,
        },
      }}
    >
      <AirIcon sx={{ fontSize: 40 }} />
      <Typography
        variant="h6"
        color="inherit"
        noWrap
        sx={{
          display: { xs: "none", sm: "block" }, // hide the app name for mobile screen size
          flexGrow: 1, // push everything to the right of the app name to the right side
        }}
      >
        VocabFlow
      </Typography>
      <CustomLinearProgress words={words} />
      <CustomCircularProgress
        value={overallScoreLanguage1To2}
        tooltipTitle="Your aggregated score from language 1 to language 2"
        footer="→"
      />
      <CustomCircularProgress
        value={overallScoreLanguage2To1}
        tooltipTitle="Your aggregated score from language 2 to language 1"
        footer="←"
      />
      <Tooltip arrow title="Logout">
        <IconButton aria-label="logout" color="inherit" onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )
}
