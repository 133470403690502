import { FlashcardsDialogProps } from "./FlashcardsDialogProps"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { FlashcardArray } from "react-quizlet-flashcard"
import { WordData } from "../model/WordData"
import * as _ from "lodash"
import Flashcard from "./Flashcard"
import FlashcardControls from "./FlashcardControls"
import { AxiosResponse } from "axios"
import {
  patchWordScoreIncrement,
  patchWordScoreReset,
} from "../requests/Requests"

export function FlashcardsDialog(props: FlashcardsDialogProps) {
  const { open, words, selectedWords, handleClose, collection } = props

  // deck vars
  const [cardWords, setCardWords] = useState<WordData[]>([])
  const [cards, setCards] = useState<any[]>([])
  const [currentCard, setCurrentCard] = useState<number>(1)
  const [currentCardId, setCurrentCardId] = useState<number>(0)

  // state machine vars
  const [mode, setMode] = useState<string>("all")
  const [selectedModeEnabled, setSelectedModeEnabled] = useState<boolean>(false)
  const [finalizedModeEnabled, setFinalizedModeEnabled] =
    useState<boolean>(false)
  const [language, setLanguage] = useState<string>("language1")
  const [invalidState, setInvalidState] = useState<boolean>(false)

  // custom flashcard deck control
  const controlRef = useRef<any>({})
  const currentCardFlipRef = useRef<any>()

  async function handleThumbsDownButtonClicked() {
    try {
      // reset score in the DB
      const wordScoreResetResponse: AxiosResponse = await patchWordScoreReset(
        cardWords[currentCardId].id,
        language === "language1" ? 1 : 2
      )

      // check the response
      if (wordScoreResetResponse.status !== 200) {
        throw Error("Bad response on patchWordScoreReset")
      }

      // update the local data model
      const newScore: number = wordScoreResetResponse.data
      if (language === "language1") {
        const newCardWords = [...cardWords]
        newCardWords[currentCardId].score_word = newScore
        setCardWords(newCardWords)
      } else if (language === "language2") {
        const newCardWords = [...cardWords]
        newCardWords[currentCardId].score_trans = newScore
        setCardWords(newCardWords)
      }

      // move to the next card
      controlRef.current.nextCard()
    } catch (error) {
      console.log(error)
    }
  }

  async function handleThumbsUpButtonClicked() {
    try {
      // increment score in the DB
      const wordScoreIncrementResponse: AxiosResponse =
        await patchWordScoreIncrement(
          cardWords[currentCardId].id,
          language === "language1" ? 1 : 2
        )

      // check the response
      if (wordScoreIncrementResponse.status !== 200) {
        throw Error("Bad response on patchWordScoreIncrement")
      }

      // update the local data model
      const newScore: number = wordScoreIncrementResponse.data
      if (language === "language1") {
        const newCardWords = [...cardWords]
        newCardWords[currentCardId].score_word = newScore
        setCardWords(newCardWords)
      } else if (language === "language2") {
        const newCardWords = [...cardWords]
        newCardWords[currentCardId].score_trans = newScore
        setCardWords(newCardWords)
      }

      // move to the next card
      controlRef.current.nextCard()
    } catch (error) {
      console.log(error)
    }
  }

  function getFlashcardBackgroundColor(
    language: string | undefined,
    gender: string
  ): string {
    if (
      !language ||
      (!language.toLowerCase().includes("german") &&
        !language.toLowerCase().includes("deutsch") &&
        !language.toLowerCase().includes("🇩🇪"))
    ) {
      return "white"
    }
    switch (gender) {
      case "masculine":
        return "#adeefe"
      case "feminine":
        return "#fe9893"
      case "neuter":
        return "#fefc99"
      case "plural":
        return "#d3b8fe"
      default:
        return "white"
    }
  }

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMode((event.target as HTMLInputElement).value)
    controlRef.current.resetArray()
    setCurrentCard(1)
    setCurrentCardId(0)
  }

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLanguage((event.target as HTMLInputElement).value)
  }

  function handleShuffle() {
    const shuffledCardWords = _.shuffle(cardWords)
    setCardWords(shuffledCardWords)
    controlRef.current.resetArray()
    setCurrentCard(1)
    setCurrentCardId(0)
  }

  const handleClick = (event: React.MouseEvent) => {
    currentCardFlipRef.current()
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowLeft") {
      controlRef.current.prevCard()
    } else if (event.key === "ArrowRight") {
      controlRef.current.nextCard()
    } else if (event.key === " ") {
      currentCardFlipRef.current()
    } else if (event.key === "ArrowUp") {
      handleThumbsUpButtonClicked().catch((e) => console.error(e))
    } else if (event.key === "ArrowDown") {
      handleThumbsDownButtonClicked().catch((e) => console.error(e))
    }
  }

  // state machine for switching mode if mode disabled state changes
  useEffect(() => {
    setInvalidState(false)
    if (mode === "finalized" && !finalizedModeEnabled) {
      setInvalidState(true)
    } else if (mode === "selected" && !selectedModeEnabled) {
      setInvalidState(true)
    }
  }, [mode, finalizedModeEnabled, selectedModeEnabled])

  useEffect(() => {
    if (invalidState) {
      setMode("all")
    }
  }, [invalidState])

  // state machine for enable/disable finalized mode
  useEffect(() => {
    if (words.filter((w) => w.is_final).length) {
      setFinalizedModeEnabled(true)
    } else {
      setFinalizedModeEnabled(false)
    }
  }, [words])

  // state machine for enable/disable selected mode
  useEffect(() => {
    if (selectedWords.length) {
      setSelectedModeEnabled(true)
    } else {
      setSelectedModeEnabled(false)
    }
  }, [selectedWords.length])

  useEffect(() => {
    async function initializeDialog() {
      try {
        if (open) {
          // set the deck words according to the mode
          let wordsToSet: WordData[] = []
          if (mode === "all") {
            wordsToSet = _.cloneDeep(words)
          } else if (mode === "selected") {
            wordsToSet = _.cloneDeep(selectedWords)
          } else if (mode === "finalized") {
            wordsToSet = _.cloneDeep(words).filter((w) => w.is_final)
          }
          const shuffledWords = _.shuffle(wordsToSet)
          setCardWords(shuffledWords)
          setCurrentCard(1)
          setCurrentCardId(0)
        }
      } catch (error) {
        console.log(error)
      }
    }

    initializeDialog().catch((error) => {
      console.log(error)
    })
  }, [mode, open, selectedWords, words])

  useEffect(() => {
    async function buildCards() {
      try {
        const newCards: any[] = cardWords.map(
          (word: WordData, index: number) => {
            return {
              id: index,
              frontHTML: (
                <Flashcard
                  word={language === "language1" ? word.word : word.trans}
                  is_final={word.is_final}
                  pos={word.pos}
                  example={
                    language === "language1" ? word.example : word.example_trans
                  }
                  plural={language === "language1" ? word.plural : ""}
                  gender={language === "language1" ? word.gender : ""}
                  score={
                    language === "language1"
                      ? word.score_word
                      : word.score_trans
                  }
                />
              ),
              backHTML: (
                <Flashcard
                  word={language === "language1" ? word.trans : word.word}
                  is_final={word.is_final}
                  pos={word.pos}
                  example={
                    language === "language1" ? word.example_trans : word.example
                  }
                  plural={language === "language1" ? "" : word.plural}
                  gender={language === "language1" ? "" : word.gender}
                  score={
                    language === "language1"
                      ? word.score_trans
                      : word.score_word
                  }
                />
              ),
              frontCardStyle: {
                backgroundColor: getFlashcardBackgroundColor(
                  language === "language1"
                    ? collection?.language1
                    : collection?.language2,
                  word.gender
                ),
              },
              backCardStyle: {
                backgroundColor: getFlashcardBackgroundColor(
                  language === "language1"
                    ? collection?.language2
                    : collection?.language1,
                  word.gender
                ),
              },
            }
          }
        )
        setCards(newCards)
      } catch (error) {
        console.log(error)
      }
    }

    buildCards().catch((error) => {
      console.log(error)
    })
  }, [cardWords, language, collection?.language1, collection?.language2])

  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      fullWidth={!!words.length}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Flashcards</DialogTitle>
      <DialogContent>
        {!words.length ? (
          <Typography>Add a word to use flashcards.</Typography>
        ) : (
          <Grid
            container
            spacing="5px"
            direction="column"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <FormControl>
                <RadioGroup
                  row
                  name="mode-group"
                  value={mode}
                  onChange={handleModeChange}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    value="finalized"
                    control={<Radio />}
                    disabled={!finalizedModeEnabled}
                    label="Finalized"
                  />
                  <FormControlLabel
                    value="selected"
                    control={<Radio />}
                    disabled={!selectedModeEnabled}
                    label="Selected"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item width="100%">
              <div onClick={handleClick}>
                <FlashcardArray
                  cards={cards}
                  forwardRef={controlRef}
                  currentCardFlipRef={currentCardFlipRef}
                  controls={false}
                  showCount={false}
                  onCardChange={(id, index) => {
                    setCurrentCardId(id)
                    setCurrentCard(index)
                  }}
                  FlashcardArrayStyle={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </div>
            </Grid>
            <Grid item>
              <FlashcardControls
                count={cards.length}
                currentCard={currentCard}
                controlRef={controlRef}
                handleThumbsDownButtonClicked={handleThumbsDownButtonClicked}
                handleThumbsUpButtonClicked={handleThumbsUpButtonClicked}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {!!words.length && (
          <FormControl>
            <RadioGroup
              row
              name="language-group"
              value={language}
              onChange={handleLanguageChange}
              sx={{ pl: "16px" }}
            >
              <FormControlLabel
                value="language1"
                control={<Radio />}
                label={collection?.language1}
              />
              <FormControlLabel
                value="language2"
                control={<Radio />}
                label={collection?.language2}
              />
            </RadioGroup>
          </FormControl>
        )}
        <div style={{ flex: "1 0 0" }} />
        {!!words.length && <Button onClick={handleShuffle}>Shuffle</Button>}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
