import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Box from "@mui/material/Box"
import React, { useEffect, useState } from "react"
import Dialog from "@mui/material/Dialog"
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material"
import { SelectChangeEvent } from "@mui/material/Select"
import { WordDialogProps } from "./WordDialogProps"
import { WordData } from "../model/WordData"
import { DraftSwitch } from "./DraftSwitch"
import { TAGS_HELPER } from "../localization/UserMessages"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { getExistingTag, isStringInArray } from "../routes/Dashboard"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import DeleteDialog from "./DeleteDialog"
import { deleteWord, getWords } from "../requests/Requests"
import { AxiosResponse } from "axios"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function WordDialog(props: WordDialogProps) {
  const {
    word,
    open,
    setOpen,
    title,
    posValues,
    genderValues,
    handleSubmit,
    submitText,
    handleCancel,
    cancelText,
    tagOptions,
    activeCollection,
    tagSelection,
    buttonsEnabled,
    setButtonsEnabled,
    setUncommittedTag,
    showDeleteIcon,
    setWords,
  } = props

  // needed to add local objects to back the form fields and prevent re-rendering the entire data grid with every keystroke
  const [localWord, setLocalWord] = useState<WordData>(word)
  const [localTagSelection, setLocalTagSelection] =
    useState<string[]>(tagSelection)

  // delete dialog
  const [deleteWordDialogIsOpen, setDeleteWordDialogIsOpen] =
    useState<boolean>(false)

  function handleCancelDelete() {
    setDeleteWordDialogIsOpen(false)
  }

  useEffect(() => {
    setLocalWord(word)
  }, [word])

  useEffect(() => {
    setLocalTagSelection(tagSelection)
  }, [tagSelection])

  const handleFormSubmit = () => {
    handleSubmit(localWord, localTagSelection)
  }

  async function handleSubmitDelete() {
    try {
      setButtonsEnabled(false)
      await deleteWord(localWord.id)
      const wordsResponse: AxiosResponse = await getWords(activeCollection?.id)
      setWords(wordsResponse.data)
      setDeleteWordDialogIsOpen(false)
    } catch (error) {
      console.log(error)
    } finally {
      setOpen(false)
      setButtonsEnabled(true)
    }
  }

  const handleDeleteButtonClicked = () => {
    setDeleteWordDialogIsOpen(true)
  }

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 1, pt: 1 }}>
            <Grid container spacing={2} direction={{ xs: "column", sm: "row" }}>
              <Grid item sm={6}>
                <TextField
                  autoFocus
                  fullWidth={true}
                  id="word"
                  label={`Word (${
                    activeCollection?.language1 ?? "Language 1"
                  })`}
                  type="text"
                  variant="outlined"
                  value={localWord.word ?? ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLocalWord((prevState: WordData) => ({
                      ...prevState,
                      word: event.target.value,
                    }))
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth={true}
                  id="trans"
                  label={`Word (${
                    activeCollection?.language2 ?? "Language 2"
                  })`}
                  type="text"
                  variant="outlined"
                  value={localWord.trans ?? ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLocalWord((prevState: WordData) => ({
                      ...prevState,
                      trans: event.target.value,
                    }))
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth={true}
                  id="example"
                  label={`Example (${activeCollection?.language1})`}
                  type="text"
                  variant="outlined"
                  value={localWord.example ?? ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLocalWord((prevState: WordData) => ({
                      ...prevState,
                      example: event.target.value,
                    }))
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth={true}
                  id="example_trans"
                  label={`Example (${activeCollection?.language2})`}
                  type="text"
                  variant="outlined"
                  value={localWord.example_trans ?? ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLocalWord((prevState: WordData) => ({
                      ...prevState,
                      example_trans: event.target.value,
                    }))
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <FormControl fullWidth={true}>
                  <InputLabel id="pos-label-id">Part of Speech</InputLabel>
                  <Select
                    labelId="pos-label-id"
                    id="pos-id"
                    value={localWord.pos ?? ""}
                    label="Part of Speech"
                    onChange={(event: SelectChangeEvent) => {
                      setLocalWord((prevState: WordData) => ({
                        ...prevState,
                        pos: event.target.value,
                      }))
                    }}
                  >
                    <MenuItem value="" sx={{ fontStyle: "italic" }}>
                      none
                    </MenuItem>
                    {posValues.map((pos) => (
                      <MenuItem value={pos} key={pos}>
                        {pos}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {(localWord.pos === "" ||
                localWord.pos === "noun" ||
                localWord.pos === "pronoun" ||
                localWord.pos === "article") && (
                <Grid item sm={6}>
                  <FormControl fullWidth={true}>
                    <InputLabel id="gender-label-id">Gender</InputLabel>
                    <Select
                      labelId="gender-label-id"
                      id="gender-id"
                      value={localWord.gender ?? ""}
                      label="Gender"
                      onChange={(event: SelectChangeEvent) => {
                        setLocalWord((prevState: WordData) => ({
                          ...prevState,
                          gender: event.target.value,
                        }))
                      }}
                    >
                      <MenuItem value="" sx={{ fontStyle: "italic" }}>
                        none
                      </MenuItem>
                      {genderValues.map((gender) => (
                        <MenuItem value={gender} key={gender}>
                          {gender}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {(localWord.pos === "" ||
                (localWord.pos === "noun" && localWord.gender !== "plural") ||
                (localWord.pos === "pronoun" &&
                  localWord.gender !== "plural") ||
                (localWord.pos === "article" &&
                  localWord.gender !== "plural")) && (
                <Grid item sm={6}>
                  <TextField
                    fullWidth={true}
                    id="plural"
                    label="Plural"
                    type="text"
                    variant="outlined"
                    value={localWord.plural ?? ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLocalWord((prevState: WordData) => ({
                        ...prevState,
                        plural: event.target.value,
                      }))
                    }}
                  />
                </Grid>
              )}
              {(localWord.pos === "" ||
                localWord.pos === "verb" ||
                localWord.pos === "past participle" ||
                localWord.pos === "adjective") && (
                <Grid item sm={6}>
                  <TextField
                    fullWidth={true}
                    id="lemma"
                    label="Lemma"
                    type="text"
                    variant="outlined"
                    value={localWord.lemma ?? ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLocalWord((prevState: WordData) => ({
                        ...prevState,
                        lemma: event.target.value,
                      }))
                    }}
                  />
                </Grid>
              )}
              <Grid item sm={6}>
                <TextField
                  fullWidth={true}
                  id="mnemonic"
                  label="Mnemonic"
                  type="text"
                  variant="outlined"
                  value={localWord.mnemonic ?? ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLocalWord((prevState: WordData) => ({
                      ...prevState,
                      mnemonic: event.target.value,
                    }))
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth={true}
                  id="usage"
                  label="Usage"
                  type="text"
                  variant="outlined"
                  value={localWord.usage ?? ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLocalWord((prevState: WordData) => ({
                      ...prevState,
                      usage: event.target.value,
                    }))
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  id="tags-filled"
                  options={tagOptions.map((t) => t.tag)}
                  freeSolo
                  value={localTagSelection ?? []}
                  onInputChange={(
                    event: React.SyntheticEvent,
                    value: string,
                    reason: string
                  ) => {
                    setUncommittedTag(value)
                  }}
                  onChange={(
                    event: any,
                    value: string[],
                    reason: AutocompleteChangeReason,
                    details?: AutocompleteChangeDetails
                  ) => {
                    if (reason === "createOption") {
                      const creationString = details?.option ?? ""
                      const tagOptionsStrings: string[] = tagOptions.map(
                        (t) => t.tag
                      )
                      if (!isStringInArray(creationString, localTagSelection)) {
                        if (
                          isStringInArray(creationString, tagOptionsStrings)
                        ) {
                          const existingTag = getExistingTag(
                            creationString,
                            tagOptionsStrings
                          )
                          setLocalTagSelection([
                            ...localTagSelection,
                            existingTag,
                          ])
                        } else {
                          setLocalTagSelection(value)
                        }
                      }
                    } else {
                      setLocalTagSelection(value)
                    }
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        {...getTagProps({ index })}
                        variant="outlined"
                        label={option}
                        key={option}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={TAGS_HELPER}
                      variant="outlined"
                      label="Tags"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  rows={2}
                  multiline
                  fullWidth={true}
                  id="notes"
                  label="Notes"
                  type="text"
                  variant="outlined"
                  value={localWord.notes ?? ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLocalWord((prevState: WordData) => ({
                      ...prevState,
                      notes: event.target.value,
                    }))
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" flexDirection="row" sx={{ pl: "16px" }}>
            <DraftSwitch word={localWord} setWord={setLocalWord} />
            {showDeleteIcon && (
              <Tooltip title="Delete" arrow>
                <IconButton
                  color="error"
                  onClick={handleDeleteButtonClicked}
                  sx={{
                    ml: {
                      xs: "0.5rem", // padding on extra-small screens
                      md: "2rem", // padding on medium and larger screens
                    },
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <div style={{ flex: "1 0 0" }} />
          <Button onClick={handleCancel} disabled={!buttonsEnabled}>
            {cancelText}
          </Button>
          <Button onClick={handleFormSubmit} disabled={!buttonsEnabled}>
            {submitText}
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteDialog
        open={deleteWordDialogIsOpen}
        title="Delete Word"
        helperText="Are you sure you want to delete this word?"
        handleSubmit={handleSubmitDelete}
        submitText="Delete"
        handleCancel={handleCancelDelete}
        cancelText="Cancel"
        buttonsEnabled={buttonsEnabled}
      />
    </>
  )
}
